import React, { Component } from "react";
import { Redirect } from 'react-router';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import axios from "axios";

import PageTitle from "./Auxilary/PageTitle";
import AuthContext from "./Context/Auth/AuthContext";
import { Urls } from "../Urls";

import "./LandingPage.css"

class LandingPage extends Component {

    state = {
        isUserDataAcquired: false
    }

    componentDidMount() {
        PageTitle.setTitle("Welcome | " + PageTitle.defaultText);
    }

    //componentDidUpdate = (prevProps, prevState, snapshot) => {
    //    console.log("LandingPage.componentDidUpdate");
    //}

    //componentWillUnmount = () => {
    //    console.log("LandingPage.componentWillUnmount");
    //}

    getUnauthLandingPage = () => {
        return (
            <div className="scrollY height100 pt-4 pb-4">
                <div className="centeredContextFlex flexDirCol">
                    <div>
                        <p>Welcome to Sesame Documentation Site.</p>
                    </div>
                    <div>
                        <p>To access the articles, please <NavLink tag={Link} className="landingPageNavLink" to={Urls.LOGIN}>sign in</NavLink>.</p>
                    </div>
                </div>
            </div>
        );
    }

    acquireUserData = (authContext) => {
        //console.log("LandingPage.acquireUserData");
        axios.post(Urls.GET_USER_PROFILE)
            .then((response) => {
                //console.log(JSON.stringify(response));
                if (response.data.IsSignedIn) {
                    authContext.onChangeContextData({
                        isAuthenticated: true,
                        //returnUrl: Urls.ARTICLE + "1",
                        userData: {
                            userId: response.data.UserId,
                            firstName: response.data.FirstName,
                            lastName: response.data.LastName,
                            email: response.data.EMail,
                            userType: response.data.UserType,
                            securityRights: response.data.SecurityRights,
                            isExternalUser: response.data.IsExternalUser
                        }
                    });
                } else {
                    this.setState({ isUserDataAcquired: true });
                }
            }, (error) => {
                this.setState({ isUserDataAcquired: true });
            });
    }

    render() {
        return (
            <AuthContext.Consumer>
                {
                    (authContext) => {
                        //console.log("[LandingPage] authContext.returnUrl: " + authContext.returnUrl);
                        //console.log("[LandingPage] authContext.isAuthenticated: " + authContext.isAuthenticated);
                        //console.log("---------");
                        return (
                            authContext.isAuthenticated ?
                                <Redirect push to={authContext.returnUrl} /> :
                                this.state.isUserDataAcquired ? this.getUnauthLandingPage() : this.acquireUserData(authContext)
                        );
                    }
                }
            </AuthContext.Consumer>
        );
    }

}

export default LandingPage;
