import React from "react";
import PropTypes from "prop-types";

const authContext = React.createContext({
    isAuthenticated: false,
    returnUrl: window.location.pathname,
    userData: {},
    onChangeContextData: (jsonData) => { }
});

authContext.propTypes = {
    isAuthenticated: PropTypes.bool,
    returnUrl: PropTypes.string,
    userData: PropTypes.object,
    onChangeContextData: PropTypes.func
};

export default authContext;

export class UserSecurityCheck {

    static ADMIN_USER = "admin_user";
    static ADMIN_ARTICLE = "admin_article";
    static KNOWLEDGE_PROFESSIONAL = "knowledge_prof";

    static isAdminArticle = (userData) => {
        if (userData.userType < 2) return true;
        var result = false;
        for (let i = 0; i < userData.securityRights.length; i++) {
            if (userData.securityRights[i] === UserSecurityCheck.ADMIN_ARTICLE) {
                result = true;
                break;
            }
        }
        return result;
    }

}
