import React, { Component, Fragment } from "react";

import { Button } from 'devextreme-react';
import notify from 'devextreme/ui/notify';

import "./ArticleResourceItem.css";

export default class ArticleResourceItem extends Component {

    copyToClipboard = () => {
        navigator.clipboard.writeText("/ArtRes/" + this.props.articleId + "/" + this.props.fileName).then(function () {
            notify("Resource URL copied.", "success", 1000);
        }, function (err) {
            notify("Unable to copy text to the clipboard.", "error", 1000);
        });
    }

    render() {
        return (
            <Fragment>
                <div className="leftMiddleContentFlex pLeft">
                    <p className="resItemTextSize parag resItemBreakWork">{this.props.fileName}</p>
                </div>
                <div className="leftMiddleContentFlex pLeft resItemP">
                    <p className="resItemTextSize parag">{this.props.fileSize}</p>
                </div>
                <div className="centeredContextFlex">
                    <Button
                        icon="copy"
                        type="default"
                        stylingMode="contained"
                        hint="Copy URL to the clipboard"
                        width="50px"
                        onClick={this.copyToClipboard}
                    />
                </div>
                <div className="centeredContextFlex resItemButtonsGap">
                    <Button
                        icon="clear"
                        type="danger"
                        stylingMode="contained"
                        hint="Remove resource"
                        width="50px"
                        onClick={() => this.props.onResourceRemove(this.props.id)}
                    />
                </div>
            </Fragment>
        );
    }

}
