import React, { Component, Fragment } from "react";

import { TreeList, Column, RowDragging, Sorting, SearchPanel, Paging, Selection } from 'devextreme-react/tree-list';
import notify from 'devextreme/ui/notify';
import { Popup } from 'devextreme-react/popup';
import { Button } from 'devextreme-react';
import { TextBox } from 'devextreme-react/text-box';
import CheckBox from 'devextreme-react/check-box';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import ValidationGroup from 'devextreme-react/validation-group';

import ConfirmPopup from "../Popups/ConfirmPopup";
import ArticleContext from "../Context/Article/ArticleContext";
import ArticleDataSource from "./ArticleDataSource";
import * as articleTypes from "./ArticleConsts";
import LoadingPopup from "../Popups/LoadingPopup";

import "./CategoryTreeList.css";

class CategoryTreeList extends Component {

    shortNameBoxRef = React.createRef();

    constructor(props) {
        super(props);

        //console.log("CategoryTreeList.constructor");

        const expandedItems = [];
        for (let i = 0; i < ArticleDataSource._categories.length; i++) {
            const item = ArticleDataSource._categories[i];
            if (item.IsExpanded) expandedItems.push(item.Id);
        }

        this.state = {
            expandedItems: expandedItems,
            showPopupShortName: false,
            popupTitle: "",
            popupShortNameValue: "",
            popupButtonText: "",
            selectedCategory: null,
            popupClickEventHandler: null,
            popupIsVisibleValue: true,
            confirmPopupVisible: false,
            categoryToRemove: null,
            showLoading: false
        }
    }

    //componentDidMount() {
    //    console.log("CategoryTreeList.componentDidMount");
    //}

    //componentWillUnmount() {
    //    console.log("CategoryTreeList.componentWillUnmount");
    //}

    onDragChange = (e) => {
        let visibleRows = e.component.getVisibleRows(),
            sourceNode = e.component.getNodeByKey(e.itemData.Id),
            targetNode = visibleRows[e.toIndex].node;

        while (targetNode && targetNode.data) {
            if (targetNode.data.Id === sourceNode.data.Id) {
                e.cancel = true;
                break;
            }
            targetNode = targetNode.parent;
        }
    }

    onReorder = (e) => {
        let visibleRows = e.component.getVisibleRows(),
            sourceData = e.itemData,
            targetData = visibleRows[e.toIndex].data,
            categories = ArticleDataSource._categories,
            sourceIndex = categories.indexOf(sourceData),
            targetIndex = categories.indexOf(targetData);

        var findByParentId = function (ar, parentId) {
            let res = [];
            for (let i = 0; i < ar.length; i++) {
                let item = ar[i];
                if (item.ParentCategoryId === parentId) {
                    res.push(item);
                }
            }
            return res;
        }

        if (sourceData.ArticleType === articleTypes.ROOT_CATEGORY || sourceData.ArticleType === articleTypes.ROOT_LEAF) {
            this.displayWarningNotify();
            return;
        }

        if (e.dropInsideItem) {
            if (sourceData.ParentCategoryId === targetData.Id) {
                notify("Unable to move node into the same parent tree.", "warning", 3000);
                return;
            }
            if (targetData.ArticleType === articleTypes.ROOT_LEAF || targetData.ArticleType === articleTypes.LEAF) {
                notify("Unable to move node into an article. Please select a category.", "warning", 3000);
                return;
            }

            let oldParentId = sourceData.ParentCategoryId;

            let newLevelCategs = findByParentId(categories, targetData.Id);

            // remove from the old parent and align order indexes
            sourceData.ParentCategoryId = targetData.Id;
            let sameLevelCategs = findByParentId(categories, oldParentId);
            let changedCategs = [];
            for (let i = 0; i < sameLevelCategs.length; i++) {
                let item = sameLevelCategs[i];
                if (item.OrderIndex !== i) {
                    item.OrderIndex = i;
                    changedCategs.push(item);
                }
            }

            newLevelCategs.push(sourceData);
            for (let i = 0; i < newLevelCategs.length; i++) {
                let item = newLevelCategs[i];
                if (item.OrderIndex !== i || item.Id === sourceData.Id) {
                    item.OrderIndex = i;
                    changedCategs.push(item);
                }
            }
            ArticleDataSource.sortCategories(ArticleDataSource._categories);

            this.setState({ showLoading: true });
            ArticleDataSource.sendCategoryChanges(changedCategs)
                .then((result) => {
                    this.setState({ showLoading: false });
                })
                .catch((error) => {
                    this.setState({ showLoading: false });
                    this.displayErrorNotify();
                });

        } else {
            if (targetData.ArticleType === articleTypes.ROOT_CATEGORY || targetData.ArticleType === articleTypes.ROOT_LEAF) {
                this.displayWarningNotify();
                return;
            }

            if (sourceIndex < targetIndex) {
                // move down
                categories.splice(targetIndex + 1, 0, sourceData);
                categories.splice(sourceIndex, 1);
            } else {
                // move up
                categories.splice(targetIndex, 0, sourceData);
                categories.splice(sourceIndex + 1, 1);
            }

            let sameLevelCategs = findByParentId(categories, sourceData.ParentCategoryId);
            let changedCategs = [];
            let firstOrderIndex = 0;
            for (let i = 0; i < sameLevelCategs.length; i++) {
                let item = sameLevelCategs[i];
                if (item.OrderIndex !== firstOrderIndex + i) {
                    item.OrderIndex = firstOrderIndex + i;
                    changedCategs.push(item);
                }
            }
            ArticleDataSource.sortCategories(ArticleDataSource._categories);

            this.setState({ showLoading: true });
            ArticleDataSource.sendCategoryChanges(changedCategs)
                .then((result) => {
                    this.setState({ showLoading: false });
                })
                .catch((error) => {
                    this.setState({ showLoading: false });
                    this.displayErrorNotify();
                });
        }
    }

    onFocusedRowChanged = (e) => {
        //console.log("CategoryTreeList.onFocusedRowChanged, rowKey: " + e.component.option('focusedRowKey'));
        let id = e.component.option('focusedRowKey');
        if (this.state.focusedRowKey !== id) {
            if (e.row === undefined) return;
            let data = e.row.data;
            data.IsSelected = true;
            this.props.articleContext.onCategoryChange(data);
        }
    }

    displayErrorNotify = () => {
        notify("Failed to commit modifications on the server.", "error", 3000);
    }

    displayWarningNotify = () => {
        notify("Built-in nodes cannot be moved, edited or removed.", "warning", 3000);
    }

    onCreateCategoryEventHandler = (e) => {
        if (!e.validationGroup.validate().isValid) {
            return;
        }

        var categ = this.state.selectedCategory;
        this.setState({ showLoading: true });
        ArticleDataSource.createNode(categ, this.state.popupShortNameValue, articleTypes.CATEGORY, this.state.popupIsVisibleValue)
            .then((result) => {
                this.setState({ showLoading: false });
            })
            .catch((error) => {
                this.setState({ showLoading: false });
                this.displayErrorNotify();
            });

        e.validationGroup.reset();

        this.setState({ showPopupShortName: false });
    }

    onChangeCategoryEventHandler = (e) => {
        if (!e.validationGroup.validate().isValid) {
            return;
        }

        this.setState({ showLoading: true });
        ArticleDataSource.modifyCategory(this.state.selectedCategory, this.state.popupShortNameValue, this.state.popupIsVisibleValue)
            .then((result) => {
                this.setState({ showLoading: false });
            })
            .catch((error) => {
                this.setState({ showLoading: false });
                this.displayErrorNotify();
            });

        e.validationGroup.reset();

        this.setState({ showPopupShortName: false });
    }

    onCreateArticleEventHandler = (e) => {
        if (!e.validationGroup.validate().isValid) {
            return;
        }

        this.setState({ showLoading: true });
        var categ = this.state.selectedCategory;
        ArticleDataSource.createNode(categ, this.state.popupShortNameValue, articleTypes.LEAF, this.state.popupIsVisibleValue)
            .then((result) => {
                this.setState({ showLoading: false });
            })
            .catch((error) => {
                this.setState({ showLoading: false });
                this.displayErrorNotify();
            });

        e.validationGroup.reset();

        this.setState({ showPopupShortName: false });
    }

    onAddCategory = (category) => {
        //console.log("CategoryTreeList.onAddCategory");
        this.setState({
            showPopupShortName: true,
            popupTitle: "Create Category",
            popupButtonText: "Create",
            popupShortNameValue: "",
            selectedCategory: category,
            popupClickEventHandler: this.onCreateCategoryEventHandler,
            popupIsVisibleValue: true
        });
    }

    onAddLeaf = (category, articleContext) => {
        //console.log("CategoryTreeList.onAddLeaf");
        this.setState({
            showPopupShortName: true,
            popupTitle: "Create Article",
            popupButtonText: "Create",
            popupShortNameValue: "",
            selectedCategory: category,
            popupClickEventHandler: this.onCreateArticleEventHandler,
            popupIsVisibleValue: false
        });
    }

    onCategoryEdit = (e, category, articleContext) => {
        //console.log("CategoryTreeList.onCategoryEdit");
        if (category.ArticleType === articleTypes.CATEGORY) {
            this.setState({
                showPopupShortName: true,
                popupTitle: "Edit Category",
                popupButtonText: "Modify",
                popupShortNameValue: category.Name,
                selectedCategory: category,
                popupClickEventHandler: this.onChangeCategoryEventHandler,
                popupIsVisibleValue: category.IsVisible
            });
        } else {
            articleContext.onArticleEditModeChange(true);
        }
    }

    onCategoryRemove = (category, articleContext) => {
        //console.log("CategoryTreeList.onCategoryRemove");
        this.setState({ confirmPopupVisible: true, categoryToRemove: category });
    }

    onCategoryRemoveConfirmed = () => {
        let category = this.state.categoryToRemove;
        this.setState({ confirmPopupVisible: false });

        this.setState({ showLoading: true });
        ArticleDataSource.removeCategory(category.Id)
            .then((result) => {
                this.setState({ showLoading: false });
            })
            .catch((error) => {
                this.setState({ showLoading: false });
                this.displayErrorNotify();
            });

    }

    onExitEditor = (articleContext) => {
        articleContext.onCategoryEditModeChange(false);
    }

    onContextMenuPreparing = (e, articleContext) => {
        //console.log("CategoryTreeList.onContextMenuPreparing");
        if (e.target === "content") {
            let items = e.items = [];
            let category = e.row.data;
            items.push({
                text: "Add Category",
                onItemClick: () => this.onAddCategory(category)
            });
            items.push({
                text: "Add Leaf",
                onItemClick: () => this.onAddLeaf(category)
            });
            if (category.ArticleType === articleTypes.CATEGORY) {
                items.push({
                    text: "Edit Category",
                    onItemClick: () => this.onCategoryEdit(e, category, articleContext)
                });
            } else if (category.ArticleType === articleTypes.LEAF || category.ArticleType === articleTypes.ROOT_LEAF) {
                items.push({
                    text: "Edit Article",
                    onItemClick: (e) => this.onCategoryEdit(e, category, articleContext)
                });
            }
            if (category.ArticleType === articleTypes.CATEGORY || category.ArticleType === articleTypes.LEAF) {
                items.push({
                    text: "Remove",
                    onItemClick: () => this.onCategoryRemove(category, articleContext)
                });
            }
            items.push({
                text: "Exit From Edit Mode",
                onItemClick: () => this.onExitEditor(articleContext)
            });
        }
    }

    hideShortNamePopup = () => {
        this.setState({ showPopupShortName: false });
    }

    shortNameChangeHandler = (e) => {
        this.setState({ popupShortNameValue: e.value });
    }

    get shortNameBox() {
        return this.shortNameBoxRef.current.instance;
    }

    focusPopupTextBox = () => {
        setTimeout(() => {
            this.shortNameBox.focus();
        }, 1000);
    }

    cbIsVisibleValueChanged = (e) => {
        this.setState({ popupIsVisibleValue: e.value });
    }

    onConfirmPopupCancel = () => {
        this.setState({ confirmPopupVisible: false });
    }

    render() {
        return (
            <ArticleContext.Consumer>
                {
                    articleContext =>
                        <Fragment>
                            <TreeList
                                dataSource={articleContext.categories}
                                dataStructure="plain"
                                parentIdExpr="ParentCategoryId"
                                keyExpr="Id"
                                hasItemsExpr="HasItems"
                                width="100%"
                                rootValue={-1}
                                showBorders={true}
                                showRowLines={true}
                                defaultExpandedRowKeys={this.state.expandedItems}
                                onFocusedRowChanged={this.onFocusedRowChanged}
                                focusedRowEnabled={true}
                                focusedRowKey={articleContext.treeListFocusedRow}
                                autoNavigateToFocusedRow={true}
                                onContextMenuPreparing={(e) => this.onContextMenuPreparing(e, articleContext)}
                                disabled={articleContext.isArticleEditMode}
                            >
                                <Column dataField="Name" caption="Name" />
                                <SearchPanel visible={true} />
                                <Sorting mode="none" />
                                <Paging enabled={false} />
                                <Selection mode="single" allowSelectAll={false} />
                                <RowDragging
                                    onDragChange={this.onDragChange}
                                    onReorder={this.onReorder}
                                    allowDropInsideItem={true}
                                    allowReordering={true}
                                    showDragIcons={true}
                                />
                            </TreeList>
                            <Popup
                                width={320}
                                height={260}
                                visible={this.state.showPopupShortName}
                                onHiding={this.hideShortNamePopup}
                                dragEnabled={true}
                                closeOnOutsideClick={true}
                                showTitle={true}
                                title={this.state.popupTitle}
                            >
                                <div className="centeredContextFlex">
                                    <ValidationGroup>
                                        <div className="treeListAfterMargin pLeft">
                                            <p>Enter a short name</p>
                                            <TextBox
                                                onValueChanged={this.shortNameChangeHandler}
                                                value={this.state.popupShortNameValue}
                                                hint="Short name"
                                                showClearButton={true}
                                                width="15rem"
                                                ref={this.shortNameBoxRef}>
                                                <Validator>
                                                    <RequiredRule message={'Short name is required'} />
                                                </Validator>
                                            </TextBox>
                                        </div>
                                        <div className="treeListAfterMargin pLeft">
                                            <CheckBox value={this.state.popupIsVisibleValue} text={'Make it visible now'} onValueChanged={this.cbIsVisibleValueChanged} />
                                        </div>
                                        <div className="treeListAfterMargin pLeft">
                                            <Button
                                                width="15rem"
                                                text={this.state.popupButtonText}
                                                type="default"
                                                stylingMode="contained"
                                                onClick={this.state.popupClickEventHandler}
                                            />
                                        </div>
                                    </ValidationGroup>
                                </div>
                            </Popup>
                            {this.state.showPopupShortName ? this.focusPopupTextBox() : null}
                            {this.state.confirmPopupVisible ? <ConfirmPopup okCallback={this.onCategoryRemoveConfirmed} cancelCallback={this.onConfirmPopupCancel} /> : null}
                            <LoadingPopup isVisible={this.state.showLoading} />
                        </Fragment >
                }
            </ArticleContext.Consumer>
        );
    }

}

export default CategoryTreeList;
