class PageTitle {

    static defaultText = "Sesame Documentation";

    static setTitle(title = "Sesame Documentation") {
        document.title = title;
    }

}

export default PageTitle;
