import React, { Component, Fragment } from "react";
import axios from "axios";

import { Button } from 'devextreme-react';
import { TextBox } from 'devextreme-react/text-box';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import { Popup } from 'devextreme-react/popup';
import ValidationGroup from 'devextreme-react/validation-group';

import PageTitle from "../Auxilary/PageTitle";
import { Urls } from "../../Urls";
import LoadingPopup from "../Popups/LoadingPopup";

import "./ForgotPassword.css";

class ForgotPassword extends Component {

    emailBoxRef = React.createRef();

    state = {
        loginName: "",
        isFailedPopupVisible: false,
        isNotPerfomedPopupVisible: false,
        isSuccessPopupVisible: false,
        showLoading: false
    }

    componentDidMount() {
        PageTitle.setTitle("Forgot password | " + PageTitle.defaultText);
        this.emailBox.focus();
    }

    usernameChangeHandler = (e) => {
        this.setState({ loginName: e.value });
    }

    get emailBox() {
        return this.emailBoxRef.current.instance;
    }

    btnPerformClick = (e) => {
        if (!e.validationGroup.validate().isValid) {
            return;
        }

        this.setState({ showLoading: true });
        axios.post(Urls.FORGOT_PASSWORD_PERFORM,
            {
                Username: this.state.loginName
            })
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.Succeeded) {
                        e.validationGroup.reset();
                        this.setState({ isSuccessPopupVisible: true, showLoading: false });
                    } else {
                        this.setState({ isNotPerfomedPopupVisible: true, showLoading: false });
                    }
                } else {
                    this.setState({ isFailedPopupVisible: true, showLoading: false });
                }
            },
                (error) => {
                    this.setState({ isFailedPopupVisible: true, showLoading: false });
                })

    }

    hideNotPerformedPopup = () => {
        this.setState({ isNotPerfomedPopupVisible: false });
    }

    hideFailedPopup = () => {
        this.setState({ isFailedPopupVisible: false });
    }

    hideSuccessPopup = () => {
        this.setState({ isSuccessPopupVisible: false });
    }

    renderForgotPasswordForm = () => {
        return (
            <div className="scrollY height100 pt-4 pb-4">
                <div className="centeredContextFlex">
                    <ValidationGroup>
                        <p className="pHeader forgAfterMargin pLeft">Forgot password</p>
                        <div className="forgAfterMargin pLeft">
                            <p>Enter your Email address:</p>
                            <TextBox
                                id="emlReg"
                                ref={this.emailBoxRef}
                                hint="Your registered email address"
                                onValueChanged={this.usernameChangeHandler}
                                value={this.state.loginName}
                                showClearButton={true}
                                width="15rem">
                                <Validator>
                                    <RequiredRule message={'Email address is required'} />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className="forgAfterMargin">
                            <Button
                                width="15rem"
                                text="Request new password"
                                hint="Request new password"
                                type="default"
                                stylingMode="contained"
                                onClick={(e) => this.btnPerformClick(e)}
                            />
                        </div>
                    </ValidationGroup>
                </div>
            </div>
        );
    }

    render() {
        return (
            <Fragment>
                {this.renderForgotPasswordForm()}
                <Popup
                    visible={this.state.isNotPerfomedPopupVisible}
                    onHiding={this.hideNotPerformedPopup}
                    dragEnabled={true}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    title="Forgot Password"
                    width={300}
                    height={200}>
                    <div className="profPopupCenter">
                        <div className="centeredContent">
                            <p className="pCenter">
                                Unable to perform operation.
                            </p>
                            <Button
                                width="14.5rem"
                                text="OK"
                                type="default"
                                stylingMode="contained"
                                onClick={() => this.hideNotPerformedPopup()}
                            />
                        </div>
                    </div>
                </Popup>
                <Popup
                    visible={this.state.isFailedPopupVisible}
                    onHiding={this.hideFailedPopup}
                    dragEnabled={true}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    title="Forgot Password"
                    width={300}
                    height={200}>
                    <div className="profPopupCenter">
                        <div className="centeredContent">
                            <p className="pCenter">
                                Failed to communicate with the server.
                            </p>
                            <Button
                                width="14.5rem"
                                text="OK"
                                type="default"
                                stylingMode="contained"
                                onClick={() => this.hideFailedPopup()}
                            />
                        </div>
                    </div>
                </Popup>
                <Popup
                    visible={this.state.isSuccessPopupVisible}
                    onHiding={this.hideSuccessPopup}
                    dragEnabled={true}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    title="Forgot Password"
                    width={300}
                    height={200}>
                    <div className="profPopupCenter">
                        <div className="centeredContent">
                            <p className="pCenter">
                                Please check your email inbox.
                            </p>
                            <Button
                                width="14.5rem"
                                text="OK"
                                type="default"
                                stylingMode="contained"
                                onClick={() => this.hideSuccessPopup()}
                            />
                        </div>
                    </div>
                </Popup>
                <LoadingPopup isVisible={this.state.showLoading} />
            </Fragment>
        );
    }

}

export default ForgotPassword;
