import React, { Component, Fragment } from "react";
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import axios from "axios";

import { Popup } from 'devextreme-react/popup';
import { Button } from 'devextreme-react';

import AuthContext from "./Context/Auth/AuthContext";

import { Urls } from "../Urls";
import { UserRights } from "./Auth/UserRights";
import LoadingPopup from "./Popups/LoadingPopup";

import "./AccountMenu.css";

class AccountMenu extends Component {

    state = {
        needRedirect: false,
        isErrorPopupVisible: false,
        showLoading: false
    }

    logoutClickEventHandler = (e, authContext) => {
        //console.log("AccountMenu.logoutClickEventHandler");
        e.preventDefault();
        this.setState({ showLoading: true });
        axios.get(Urls.LOGOUT)
            .then((response) => {
                this.setState({ needRedirect: true, showLoading: false });
                authContext.onChangeContextData({ isAuthenticated: false });
            }, (error) => {
                    this.setState({ isErrorPopupVisible: true, showLoading: false });
            });
    }

    getRegisterMenuItem = () => {
        return (
            <Fragment>
                <hr />
                <NavLink tag={Link} className="dropdown-item text-dark" to={Urls.REGISTER_USER}>Register user</NavLink>
                <hr />
            </Fragment>
        );
    }

    getAdminUserMenuItem = () => {
        return (
            <Fragment>
                <hr />
                <NavLink tag={Link} className="dropdown-item text-dark" to={Urls.ADMIN_USERS}>Admin users</NavLink>
                <hr />
            </Fragment>
        );
    }

    authMenu = (authContext) => {
        return (
            <NavItem className="dropdown">
                <NavLink className="dropdown-toggle" data-toggle="dropdown" to="#" href="#" title={authContext.userData.Name}>{authContext.userData.firstName.length > 12 ? authContext.userData.firstName.substring(0, 10) + "..." : authContext.userData.firstName}</NavLink>
                <div className="dropdown-menu accountMenu">
                    <NavLink tag={Link} className="dropdown-item text-dark" to={Urls.USER_PROFILE}>My Profile</NavLink>
                    {authContext.userData.userType < 2 || authContext.userData.securityRights.includes(UserRights.ADMIN_USER) ? this.getRegisterMenuItem() : null}
                    {authContext.userData.userType < 2 || authContext.userData.securityRights.includes(UserRights.ADMIN_USER) ? this.getAdminUserMenuItem() : null}
                    <a className="dropdown-item text-dark nav-link" href="#" onClick={(e) => { this.logoutClickEventHandler(e, authContext); return false; }}>Sign Out</a>
                </div>
            </NavItem>
        );
    }

    unAuthMenu = () => {
        return (
            <NavItem>
                <NavLink tag={Link} className="navbar-dark" to="/Auth/Login">Sign In</NavLink>
            </NavItem>
        );
    }

    hideErrorPopup = () => {
        this.setState({ isErrorPopupVisible: false });
    }

    render() {
        return (
            <AuthContext.Consumer>
                {
                    (authContext) => {
                        return (
                            <Fragment>
                                <Popup
                                    visible={this.state.isErrorPopupVisible}
                                    onHiding={this.hideErrorPopup}
                                    dragEnabled={true}
                                    closeOnOutsideClick={true}
                                    showTitle={true}
                                    title="Error"
                                    width={300}
                                    height={250}>
                                    <p>
                                        Failed to communicate with the server.<br />Please try again later.
                                    </p>
                                    <Button
                                        width="14.5rem"
                                        text="OK"
                                        type="default"
                                        stylingMode="contained"
                                        onClick={this.hideErrorPopup} />
                                </Popup>
                                {this.state.needRedirect ? <Redirect push to={Urls.LOGIN} /> : null}
                                <ul className="navbar-nav">
                                    {authContext.isAuthenticated ? this.authMenu(authContext) : this.unAuthMenu()}
                                </ul>
                                <LoadingPopup isVisible={this.state.showLoading} />
                            </Fragment>
                        );
                    }
                }
            </AuthContext.Consumer>
        );
    }

}

export default AccountMenu;
