import { Guid, GenericEvent } from "../../libs/AuxLibs";
import axios from "axios";
import { Urls } from "../../Urls";
import * as articleTypes from "./ArticleConsts";

class ArticleDataSource {

    static VERSION = "1.0.1.1";

    static _clientId = new Guid().toString();
    static _dsUri = (window.location.protocol === "https:" ? "wss:" : "ws:") + "//" + window.location.host + "/articleprovider";
    static _webSocket = null;
    static _authUserData = {}; // check Login.js for values

    static _categories = [];
    static _isCategoriesAcquired = false;
    static _isCategoriesAcquiring = false;
    static _isCategoriesAcquireError = false;

    static _articleStore = [];
    static _articleStoreSubscribed = [];

    static _messages = [];
    static _isSendQueueActive = false;

    static _reSubscribe = false;
    static _needArticleImmediateRefresh = false;

    static connectionChangedEvent = new GenericEvent();
    static articleCommentsChangedEvent = new GenericEvent();
    static articleResourcesChangedEvent = new GenericEvent();
    static categoryChangedEvent = new GenericEvent();
    static categoryRemovedEvent = new GenericEvent();

    static staticConstructor() {
        ArticleDataSource.initialize();
    }

    static enqueueIntoMessageQueue = (message) => {
        if (ArticleDataSource._webSocket.readyState === ArticleDataSource._webSocket.OPEN) {
            ArticleDataSource._messages.push(message);
            if (!ArticleDataSource._isSendQueueActive) {
                ArticleDataSource._isSendQueueActive = true;
                ArticleDataSource.signalMessageQueue(1);
            }
        }
    };

    static signalMessageQueue = (interval) => {
        setTimeout(() => {
            if (ArticleDataSource._webSocket.readyState !== ArticleDataSource._webSocket.OPEN) {
                ArticleDataSource._messages = [];
                ArticleDataSource._isSendQueueActive = false;
            }
            else if (ArticleDataSource._webSocket.bufferedAmount > 0) {
                ArticleDataSource.signalMessageQueue(1);
            }
            else if (ArticleDataSource._messages.length > 0) {
                var msg = ArticleDataSource._messages[0];
                ArticleDataSource._messages.splice(0, 1);
                //console.log("WebSocket.send, " + msg);
                ArticleDataSource._webSocket.send(msg);
                //console.log("WebSocket.sent");
                ArticleDataSource.signalMessageQueue(1);
            } else {
                ArticleDataSource._isSendQueueActive = false;
            }
        }, interval);
    };

    static initialize() {
        var ws = new WebSocket(ArticleDataSource._dsUri);
        ArticleDataSource._webSocket = ws;

        ws.onopen = function (event) {
            console.log("WebSocket.onopen");
            ArticleDataSource.enqueueIntoMessageQueue("CLIENTID:" + ArticleDataSource._clientId);
            ArticleDataSource.connectionChangedEvent.raiseEvent(ArticleDataSource, { connected: true });
            ArticleDataSource.getCategories()
                .then(() => {
                    if (ArticleDataSource._reSubscribe) {
                        // re-subscribe for categories
                        if (ArticleDataSource._categories.length > 0) ArticleDataSource.enqueueIntoMessageQueue("SUBSCRIBECATEGS");
                        // re-subscribe for articles
                        for (var articleId in ArticleDataSource._articleStore) {
                            ArticleDataSource.ensureArticleSubscription(articleId, ArticleDataSource._needArticleImmediateRefresh);
                        }
                    }
                });
        };

        ws.onclose = function (event) {
            console.log("WebSocket.onclose");
            ArticleDataSource._isCategoriesAcquired = false;
            ArticleDataSource._articleStoreSubscribed = [];
            ArticleDataSource._reSubscribe = true;
            ArticleDataSource._needArticleImmediateRefresh = true;
            ArticleDataSource.connectionChangedEvent.raiseEvent(ArticleDataSource, { connected: false });
            setTimeout(() => {
                ArticleDataSource.initialize();
            }, 1000);
        };

        ws.onmessage = function (event) {
            //console.log("WebSocket.onmessage, data: " + event.data);
            ArticleDataSource.processReceivedMessage(event.data);
        };

        ws.onerror = function (event) {
            console.log("WebSocket.onerror");
        };

    }

    static processReceivedMessage(data) {
        var message = JSON.parse(data);
        console.log("processReceivedMessage, MessageType: " + message.MessageType);
        let article = null;
        switch (message.MessageType) {
            case "WEBAPPVERSION":
                if (ArticleDataSource.VERSION !== message.Version) {
                    window.location.reload();
                }
                break;

            case "ARTICLEADDCOMMENT":
                article = ArticleDataSource._articleStore[message.ArticleId];
                article.Comments.push({
                    Id: message.Id,
                    CreatedTime: message.CreatedTime,
                    Name: message.Name,
                    Message: message.Message,
                    OwnerUserId: message.OwnerUserId,
                    IsEdited: message.IsEdited
                });
                ArticleDataSource.articleCommentsChangedEvent.raiseEvent(ArticleDataSource, { article: article, commentId: message.Id });
                break;

            case "ARTICLEREMOVECOMMENT":
                article = ArticleDataSource._articleStore[message.ArticleId];
                for (let i = 0; i < article.Comments.length; i++) {
                    if (article.Comments[i].Id === message.CommentId) {
                        article.Comments.splice(i, 1);
                        break;
                    }
                }
                ArticleDataSource.articleCommentsChangedEvent.raiseEvent(ArticleDataSource, { article: article, commentId: message.CommentId });
                break;

            case "ARTICLEUPDATE":
                article = ArticleDataSource._articleStore[message.Id];
                article.CategoryId = message.CategoryId;
                article.Header = message.Header;
                article.Content = message.Content;
                article.ModifiedByName = message.ModifiedByName;
                article.IsVisible = message.IsVisible;
                article.IsCommentsAllowed = message.IsCommentsAllowed;

                for (let i = 0; i < ArticleDataSource._categories.length; i++) {
                    let category = ArticleDataSource._categories[i];
                    if (category.Id === message.Id) {
                        category.IsVisible = message.IsVisible;
                        category.Name = message.Header;
                        break;
                    }
                }

                ArticleDataSource.categoryChangedEvent.raiseEvent(ArticleDataSource, { id: message.Id });
                //ArticleDataSource.articleChangedEvent.raiseEvent(ArticleDataSource, { article: article });
                break;

            case "ARTICLEFULLUPDATE":
                console.log("ARTICLEFULLUPDATE");
                article = ArticleDataSource._articleStore[message.Id];
                article.CategoryId = message.CategoryId;
                article.Header = message.Header;
                article.Content = message.Content;
                article.Resources = message.Resources;
                article.ModifiedByName = message.ModifiedByName;
                article.IsVisible = message.IsVisible;
                article.IsCommentsAllowed = message.IsCommentsAllowed;
                article.Comments = message.Comments;

                for (let i = 0; i < ArticleDataSource._categories.length; i++) {
                    let category = ArticleDataSource._categories[i];
                    if (category.Id === message.Id) {
                        category.IsVisible = message.IsVisible;
                        category.Name = message.Header;
                        break;
                    }
                }

                ArticleDataSource.categoryChangedEvent.raiseEvent(ArticleDataSource, { id: message.Id });

                break;

            case "ARTICLENEWRESOURCE":
                article = ArticleDataSource._articleStore[message.ArticleId];
                article.Resources.push({
                    Id: message.Id,
                    FileName: message.FileName,
                    FileSize: message.FileSize
                });
                ArticleDataSource.articleResourcesChangedEvent.raiseEvent(ArticleDataSource, { article: article });
                break;

            case "ARTICLERESOURCEREMOVED":
                article = ArticleDataSource._articleStore[message.ArticleId];
                for (let i = 0; i < article.Resources.length; i++) {
                    let res = article.Resources[i];
                    if (res.Id === message.Id) {
                        article.Resources.splice(i, 1);
                        break;
                    }
                }
                ArticleDataSource.articleResourcesChangedEvent.raiseEvent(ArticleDataSource, { article: article });
                break;

            case "CATEGORYADD":
                let node = {
                    Id: message.Id,
                    Name: message.Name,
                    ParentCategoryId: message.ParentCategoryId,
                    OrderIndex: message.OrderIndex,
                    IsVisible: message.IsVisible,
                    ArticleType: message.ArticleType,
                    HasItems: message.ArticleType === articleTypes.CATEGORY
                };
                if (message.ArticleType === articleTypes.CATEGORY) {
                    node.IsSelected = false;
                    node.IsExpanded = false;
                }
                ArticleDataSource._categories.push(node);
                ArticleDataSource.sortCategories(ArticleDataSource._categories);
                ArticleDataSource.categoryChangedEvent.raiseEvent(ArticleDataSource, { id: message.Id });
                break;

            case "CATEGORIESUPDATES":
                message.Categories.forEach(categ => {
                    let art = ArticleDataSource._articleStore[categ.Id];
                    if (art !== undefined) {
                        art.CategoryId = categ.ParentCategoryId;
                    }
                    let cat = null;
                    for (let i = 0; i < ArticleDataSource._categories.length; i++) {
                        let category = ArticleDataSource._categories[i];
                        if (category.Id === categ.Id) {
                            cat = category;
                            cat.OrderIndex = categ.OrderIndex;
                            cat.ParentCategoryId = categ.ParentCategoryId;
                            cat.IsVisible = categ.IsVisible;
                            cat.Name = categ.Name;
                            break;
                        }
                    }
                });
                ArticleDataSource.sortCategories(ArticleDataSource._categories);
                message.Categories.forEach(categ => {
                    ArticleDataSource.categoryChangedEvent.raiseEvent(ArticleDataSource, { id: categ.Id });
                });
                break;

            case "CATEGORYREMOVE":
                ArticleDataSource.removeCategoryRecursive(message.Id, false);
                ArticleDataSource.sortCategories(ArticleDataSource._categories);
                break;

            default:
                console.log("ArticleDataSource.onmessage. Unknown message type: " + data);
        }
    }

    static getCategories() {
        return new Promise((resolve, reject) => {
            if (ArticleDataSource._isCategoriesAcquired) {
                setTimeout(() => resolve(ArticleDataSource._categories), 1);
            } else {
                if (ArticleDataSource._isCategoriesAcquiring) {

                    var check = () => {
                        setTimeout(() => {
                            if (ArticleDataSource._isCategoriesAcquired) {
                                resolve(ArticleDataSource._categories);
                            }
                            else if (ArticleDataSource._isCategoriesAcquireError) {
                                reject("error");
                            } else {
                                check();
                            }
                        }, 500);
                    };
                    check();

                } else {
                    ArticleDataSource._isCategoriesAcquiring = true;
                    axios.post(Urls.ARTICLE_API_GET_CATEGORIES, { ClientId: ArticleDataSource._clientId })
                        .then((response) => {
                            if (response.status === 200) {
                                ArticleDataSource._reSubscribe = true;

                                var serverData = response.data.Categories;
                                var categs = [];
                                for (let i = 0; i < serverData.length; i++) {
                                    var serverC = serverData[i];
                                    var c = {
                                        Id: serverC.Id,
                                        Name: serverC.Name,
                                        ParentCategoryId: serverC.ParentCategoryId,
                                        OrderIndex: serverC.OrderIndex,
                                        IsVisible: serverC.IsVisible,
                                        ArticleType: serverC.ArticleType,
                                        IsSelected: false,
                                        HasItems: serverC.ArticleType === articleTypes.CATEGORY || serverC.ArticleType === articleTypes.ROOT_CATEGORY
                                    };

                                    if (ArticleDataSource._categories.length === 0 && c.ArticleType === articleTypes.ROOT_LEAF) {
                                        // root leaf, by default selected, in the beginning
                                        c.IsSelected = true;
                                    }

                                    if (c.ArticleType === articleTypes.CATEGORY || c.ArticleType === articleTypes.ROOT_CATEGORY) {
                                        // Category or Root+Category in enum flags
                                        if (ArticleDataSource._categories.length === 0 && c.ArticleType === articleTypes.ROOT_CATEGORY) {
                                            // at the beginning, by default, the root node is expanded
                                            c.IsExpanded = true;
                                        } else {
                                            c.IsExpanded = false; // default is not expanded
                                        }
                                        for (let v = 0; v < ArticleDataSource._categories.length; v++) {
                                            var curC = ArticleDataSource._categories[v];
                                            if (curC.Id === c.Id) {
                                                c.IsExpanded = curC.IsExpanded; // copy local value (keep state)
                                                c.IsSelected = curC.IsSelected;
                                                break;
                                            }
                                        }
                                    }

                                    categs.push(c);
                                }
                                ArticleDataSource.sortCategories(categs);
                                ArticleDataSource._categories = categs;

                                ArticleDataSource._isCategoriesAcquireError = false;
                                ArticleDataSource._isCategoriesAcquiring = false;
                                ArticleDataSource._isCategoriesAcquired = true;
                                resolve(ArticleDataSource._categories);
                            } else {
                                ArticleDataSource._isCategoriesAcquireError = true;
                                ArticleDataSource._isCategoriesAcquiring = false;
                                reject(response.statusText);
                            }
                        })
                        .catch((error) => {
                            ArticleDataSource._isCategoriesAcquireError = true;
                            ArticleDataSource._isCategoriesAcquiring = false;
                            reject(error);
                        });
                }
            }
        });
    }

    static getCategory(id) {
        var result = null;
        for (let i = 0; i < ArticleDataSource._categories.length; i++) {
            if (ArticleDataSource._categories[i].Id === id) {
                result = ArticleDataSource._categories[i];
                break;
            }
        }
        return result;
    }

    static createNode(focusedCategory, name, artType, isVisible) {
        return new Promise((resolve, reject) => {
            // new category will be created as sub-category to the end of the parent category "list" (which is the selected category)
            if (focusedCategory.ArticleType === articleTypes.LEAF || focusedCategory.ArticleType === articleTypes.ROOT_LEAF) {
                focusedCategory = ArticleDataSource.getCategory(focusedCategory.ParentCategoryId);
            }

            let categsOnTheSameLevel = ArticleDataSource.findCategoriesByParentId(ArticleDataSource._categories, focusedCategory.Id);
            categsOnTheSameLevel.sort(function (a, b) {
                return a.OrderIndex - b.OrderIndex;
            });

            let orderIndex = categsOnTheSameLevel.length === 0 ? 0 : categsOnTheSameLevel[categsOnTheSameLevel.length - 1].OrderIndex + 1;

            axios.post(Urls.ARTICLE_API_POST_CREATE,
                {
                    ClientId: ArticleDataSource._clientId,
                    Name: name,
                    OrderIndex: orderIndex,
                    ParentCategoryId: focusedCategory.Id,
                    ArticleType: artType,
                    IsVisible: isVisible
                })
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.Succeeded) {
                            let node = {
                                Id: response.data.Id,
                                Name: name,
                                ParentCategoryId: focusedCategory.Id,
                                OrderIndex: orderIndex,
                                IsVisible: isVisible,
                                ArticleType: artType,
                                HasItems: artType === articleTypes.CATEGORY
                            };
                            if (artType === articleTypes.CATEGORY) {
                                node.IsExpanded = false;
                                node.IsSelected = false;
                            }
                            ArticleDataSource._categories.push(node);
                            ArticleDataSource.sortCategories(ArticleDataSource._categories);
                            resolve(response.data.Id);
                            ArticleDataSource.categoryChangedEvent.raiseEvent(ArticleDataSource, { id: response.data.Id });
                        } else {
                            reject("error");
                        }
                    }
                    else {
                        reject("error");
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static modifyCategory(category, name, isVisible) {
        return new Promise((resolve, reject) => {
            category.Name = name;
            category.IsVisible = isVisible;

            ArticleDataSource.sendCategoryChanges([category])
                .then((success) => {
                    // commit changes
                    category = ArticleDataSource.getCategory(category.Id);
                    category.Name = name;
                    category.IsVisible = isVisible;
                    resolve(success);
                    ArticleDataSource.categoryChangedEvent.raiseEvent(ArticleDataSource, { id: category.Id });
                })
                .catch((error) => {
                    reject(error);
                })

        });
    }

    static sendCategoryChanges(categories) {
        return new Promise((resolve, reject) => {
            axios.post(Urls.ARTICLE_API_POST_CHANGES, { ClientId: ArticleDataSource._clientId, Categories: categories })
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.Succeeded) {
                            resolve(response);
                            for (let i = 0; i < categories.length; i++) {
                                ArticleDataSource.categoryChangedEvent.raiseEvent(ArticleDataSource, { id: categories[i].Id });
                            }
                        } else {
                            reject("error");
                        }
                    } else {
                        reject("error");
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static removeCategory(id) {
        return new Promise((resolve, reject) => {
            axios.post(Urls.ARTICLE_API_POST_REMOVE_CATEG,
                {
                    ClientId: ArticleDataSource._clientId,
                    Id: id,
                })
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.Succeeded) {
                            ArticleDataSource.removeCategoryRecursive(id, false);
                            ArticleDataSource.sortCategories(ArticleDataSource._categories);
                            resolve(response);
                        } else {
                            reject("error");
                        }
                    } else {
                        reject("error");
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static getArticle(id) {
        return new Promise((resolve, reject) => {
            if (ArticleDataSource._articleStore.hasOwnProperty(id)) {
                resolve(ArticleDataSource._articleStore[id]);
            } else {
                axios.post(Urls.ARTICLE_API_GET_ARTICLE, { ClientId: ArticleDataSource._clientId, ArticleId: id })
                    .then((response) => {
                        if (response.status === 200) {
                            ArticleDataSource._articleStore[id] = response.data;
                            ArticleDataSource._articleStoreSubscribed.push(id);
                            resolve(response.data);
                        } else {
                            reject("error");
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }
        });
    }

    static createArticleComment(id, name, comment) {
        return new Promise((resolve, reject) => {
            axios.post(Urls.ARTICLE_API_POST_COMMENT, { ClientId: ArticleDataSource._clientId, ArticleId: id, Message: comment })
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.Succeeded) {
                            let article = ArticleDataSource._articleStore[id];
                            article.Comments.push({
                                Id: response.data.CommentId,
                                CreatedTime: response.data.CreatedTime,
                                Name: name,
                                Message: comment,
                                OwnerUserId: ArticleDataSource._authUserData.Id
                            });
                            ArticleDataSource.articleCommentsChangedEvent.raiseEvent(ArticleDataSource, { article: article, commentId: response.data.CommentId });
                            resolve("ok");
                        } else {
                            reject("error");
                        }
                    } else {
                        reject("error");
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static removeArticleComment(articleId, commentId) {
        return new Promise((resolve, reject) => {
            axios.post(Urls.ARTICLE_API_POST_REMOVE_COMMENT, { ClientId: ArticleDataSource._clientId, CommentId: commentId })
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.Succeeded) {
                            let article = ArticleDataSource._articleStore[articleId];
                            for (let i = 0; i < article.Comments.length; i++) {
                                if (article.Comments[i].Id === commentId) {
                                    article.Comments.splice(i, 1);
                                    break;
                                }
                            }
                            resolve("ok");
                            ArticleDataSource.articleCommentsChangedEvent.raiseEvent(ArticleDataSource, { article: article, commentId: commentId });
                        } else {
                            reject("error");
                        }
                    } else {
                        reject("error");
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static modifyArticle(id, header, content, isVisible, isCommentsAllowed) {
        return new Promise((resolve, reject) => {
            axios.post(Urls.ARTICLE_API_POST_ART_CHANGES,
                {
                    ClientId: ArticleDataSource._clientId,
                    Id: id,
                    Header: header,
                    Content: content,
                    IsVisible: isVisible,
                    IsCommentsAllowed: isCommentsAllowed
                })
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.Succeeded) {
                            let article = ArticleDataSource._articleStore[id];
                            article.Header = header;
                            article.Content = content;
                            article.ModifiedByName = ArticleDataSource._authUserData.lastName + " " + ArticleDataSource._authUserData.firstName;
                            article.IsVisible = isVisible;
                            article.IsCommentsAllowed = isCommentsAllowed;

                            let category = ArticleDataSource.getCategory(id);
                            category.Name = header;
                            category.IsVisible = isVisible;

                            resolve(response);

                            ArticleDataSource.categoryChangedEvent.raiseEvent(ArticleDataSource, { id: id });
                        } else {
                            reject("error");
                        }
                    } else {
                        reject("error");
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static removeResource(articleId, resourceId) {
        return new Promise((resolve, reject) => {
            axios.post(Urls.ARTICLE_API_POST_REMOVE_RES,
                {
                    ClientId: ArticleDataSource._clientId,
                    ArticleId: articleId,
                    ResourceId: resourceId
                })
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.Succeeded) {
                            let article = ArticleDataSource._articleStore[articleId];
                            for (let i = 0; i < article.Resources.length; i++) {
                                if (article.Resources[i].Id === resourceId) {
                                    article.Resources.splice(i, 1);
                                    break;
                                }
                            }

                            resolve(response);

                            ArticleDataSource.categoryChangedEvent.raiseEvent(ArticleDataSource, { id: articleId });
                        } else {
                            reject("error");
                        }
                    } else {
                        reject("error");
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static beginSearch(textToFind) {
        return new Promise((resolve, reject) => {
            axios.post(Urls.ARTICLE_API_POST_SEARCH,
                {
                    ClientId: ArticleDataSource._clientId,
                    TextToFind: textToFind
                })
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.Succeeded) {
                            resolve(response);
                        } else {
                            reject("error");
                        }
                    } else {
                        reject("error");
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static ensureArticleSubscription(id, needImmediateRefresh) {
        if (ArticleDataSource._articleStoreSubscribed.indexOf(id) === -1) {
            ArticleDataSource._articleStoreSubscribed.push(id);
            ArticleDataSource.enqueueIntoMessageQueue("SUBSCRIBEARTICLE:" + id + ";IR:" + needImmediateRefresh);
        }
    }

    static cloneArray(array) {
        if (array === null) return null;
        return [...array];
    }

    static deepCloneArray(array) {
        if (array === null) return null;
        var result = [];
        for (let i = 0; i < array.length; i++) {
            result.push({ ...array[i] });
        }
        return result;
    }

    static selectCategory(id, state) {
        for (let i = 0; i < ArticleDataSource._categories.length; i++) {
            let categ = ArticleDataSource._categories[i];
            if (categ.Id === id) {
                categ.IsSelected = state;
            } else {
                categ.IsSelected = false;
            }
        }
    }

    static getVisibleCategories() {
        let res = [];
        for (let i = 0; i < ArticleDataSource._categories.length; i++) {
            let item = ArticleDataSource._categories[i];
            if (item.IsVisible) {
                res.push(item);
            }
        }
        return res;
    }

    static findCategoriesByParentId = function (categoryList, parentId) {
        let res = [];
        for (let i = 0; i < categoryList.length; i++) {
            let item = categoryList[i];
            if (item.ParentCategoryId === parentId) {
                res.push(item);
            }
        }
        return res;
    }

    static sortCategories(categories) {
        var orderedArray = [];

        var findRecursive = function (ar) {
            ar.sort(function (a, b) {
                return a.OrderIndex - b.OrderIndex;
            });
            ar.forEach(i => orderedArray.push(i));
            ar.forEach(i => {
                let subCats = ArticleDataSource.findCategoriesByParentId(categories, i.Id);
                findRecursive(subCats);
            });
        }

        let cats = ArticleDataSource.findCategoriesByParentId(categories, -1);
        findRecursive(cats);

        categories.splice(0);
        orderedArray.forEach(i => categories.push(i));
    }

    static removeCategoryRecursive = function (id, isSub) {
        let categoryToRemove = ArticleDataSource.getCategory(id);

        let categChildren = ArticleDataSource.findCategoriesByParentId(ArticleDataSource._categories, id);
        categChildren.forEach(c => ArticleDataSource.removeCategoryRecursive(c.Id, true));

        ArticleDataSource._categories.splice(ArticleDataSource._categories.indexOf(categoryToRemove), 1);

        if (!isSub) {
            let categNeighbours = ArticleDataSource.findCategoriesByParentId(ArticleDataSource._categories, categoryToRemove.ParentCategoryId);
            if (categNeighbours.length > 0) {
                for (let i = 0; i < categNeighbours.length; i++) {
                    categNeighbours[i].OrderIndex = i;
                }
                for (let i = 0; i < categNeighbours.length; i++) {
                    ArticleDataSource.categoryChangedEvent.raiseEvent(ArticleDataSource, { id: categNeighbours[i].Id });
                }
            }
        }

        if (ArticleDataSource._articleStore.hasOwnProperty(id)) {
            delete ArticleDataSource._articleStore[id];
        }

        ArticleDataSource.categoryRemovedEvent.raiseEvent(ArticleDataSource, { id: id });
    };


}
ArticleDataSource.staticConstructor();

export default ArticleDataSource;
