export const Urls = {
    LANDING: "/",
    LOGIN: "/Auth/Login",
    LOGOUT: "/Auth/Logout",
    CONFIRM_EMAIL: "/Auth/ConfirmEmail",
    PERFORM_LOGIN: "/Auth/PerformLogin",
    REGISTER_USER: "/Auth/Register",
    ADMIN_USERS: "/Auth/AdminUsers",
    ADMIN_USERS_GETUSERS: "/Auth/GetUsers",
    ADMIN_USERS_UPDATE_SETTINGS: "/Auth/UpdateUserSettings",
    ADMIN_USERS_RESEND_CONFIRM_EMAIL: "/Auth/ResendConfirmEmail",
    GET_USER_PROFILE: "/Auth/GetUserProfile",
    FORGOT_PASSWORD: "/Auth/ForgotPassword",
    FORGOT_PASSWORD_PERFORM: "/Auth/PerformForgotPassword",
    RESET_PASSWORD: "/Auth/ResetPassword",
    RESET_PASSWORD_PERFORM: "/Auth/ResetPasswordPerform",
    USER_PROFILE: "/Auth/UserProfile",
    USER_PROFILE_UPDATE: "/Auth/UpdateUserProfile",
    ARTICLE: "/Article/",
    ARTICLE_API_GET_ARTICLE: "/ArticleApi/GetArticle",
    ARTICLE_API_GET_CATEGORIES: "/ArticleApi/GetCategories",
    ARTICLE_API_POST_COMMENT: "/ArticleApi/CreateComment",
    ARTICLE_API_POST_REMOVE_COMMENT: "/ArticleApi/RemoveComment",
    ARTICLE_API_POST_CHANGES: "/ArticleApi/CategoriesChanged",
    ARTICLE_API_POST_CREATE: "/ArticleApi/CreateCategoryOrArticle",
    ARTICLE_API_POST_FILEUPLOAD: "/ArticleApi/UploadFile/",
    ARTICLE_API_POST_ART_CHANGES: "/ArticleApi/ArticleChanged",
    ARTICLE_API_POST_REMOVE_RES: "/ArticleApi/RemoveResource",
    ARTICLE_API_POST_REMOVE_CATEG: "/ArticleApi/CategoryRemove",
    ARTICLE_API_POST_SEARCH: "/ArticleApi/SearchText",
    CONTACT: "/Contact",
    CONTACT_PERFORM: "/ContactApi/SendMessage"
};
