import React, { Component, Fragment } from "react";

import { TreeView } from 'devextreme-react';
import ContextMenu from 'devextreme-react/context-menu';

import ArticleContext from "../Context/Article/ArticleContext";
import ArticleDataSource from "./ArticleDataSource";
import AuthContext from "../Context/Auth/AuthContext";
import { UserSecurityCheck } from "../Context/Auth/AuthContext";

import "./CategoryTreeView.css";

class CategoryTreeView extends Component {

    contextMenuItems = [{ id: 0, text: "Enter Edit Mode" }];

    //constructor(props) {
    //    super(props);
    //    console.log("CategoryTreeView.constructor");
    //}

    //componentDidMount() {
    //    console.log("CategoryTreeView.componentDidMount");
    //}

    //componentWillUnmount() {
    //    console.log("CategoryTreeView.componentWillUnmount");
    //}

    onItemExpanded = (e) => {
        ArticleDataSource.getCategory(e.itemData.Id).IsExpanded = true;
    }

    onItemCollapsed = (e) => {
        ArticleDataSource.getCategory(e.itemData.Id).IsExpanded = false;
    }

    onItemSelectionChanged = (e) => {
        this.props.articleContext.onCategoryChange(e.itemData);
    }

    //onItemContextMenu = (e, authContext) => {
    //    //console.log("CategoryTreeView.onItemContextMenu");
    //    if (UserSecurityCheck.isAdminArticle(authContext.userData)) {
    //        e.event.preventDefault();
    //        //var items = [];
    //        //items.push({ id: 0, text: "Enter Edit Mode", itemData: e.itemData });
    //        // I'm not sure, if this option is good here
    //        //if (this.itemData !== null && (this.itemData.ArticleType === articleTypes.LEAF || this.itemData.ArticleType === articleTypes.ROOT_LEAF)) {
    //        //    items.push({ id: 1, text: "Edit article", itemData: this.itemData });
    //        //}
    //    }
    //}

    onBeginEditContextItemClick = (e) => {
        // let's edit the categories
        //console.log("CategoryTreeView.onBeginEditContextItemClick");
        if (e.itemData.id === 0) {
            this.props.articleContext.onCategoryEditModeChange(true);
        }
        //else {
        //    this.props.articleContext.onArticleEditModeChange(true);
        //}
    }

    render() {
        return (
            <AuthContext.Consumer>
                {
                    authContext =>
                        <ArticleContext.Consumer>
                            {
                                articleContext =>
                                    <Fragment>
                                        <div id="treeView">
                                            <TreeView
                                                //onItemContextMenu={(e) => this.onItemContextMenu(e, authContext)}
                                                disabled={this.props.isArticleEditMode}
                                                items={articleContext.visibleCategories}
                                                dataStructure="plain"
                                                displayExpr="Name"
                                                parentIdExpr="ParentCategoryId"
                                                keyExpr="Id"
                                                expandedExpr="IsExpanded"
                                                selectedExpr="IsSelected"
                                                hasItemsExpr="HasItems"
                                                width="100%"
                                                searchMode="contains"
                                                searchEnabled={true}
                                                selectByClick={true}
                                                selectionMode="single"
                                                showCheckBoxesMode="none"
                                                rootValue={-1}
                                                onItemExpanded={this.onItemExpanded}
                                                onItemCollapsed={this.onItemCollapsed}
                                                onItemSelectionChanged={this.onItemSelectionChanged}
                                            />
                                        </div>
                                        {UserSecurityCheck.isAdminArticle(authContext.userData) ?
                                            <ContextMenu
                                                dataSource={this.contextMenuItems}
                                                width={200}
                                                target="#treeView"
                                                onItemClick={this.onBeginEditContextItemClick} /> : null}
                                    </Fragment>
                            }
                        </ArticleContext.Consumer>
                }
            </AuthContext.Consumer>
        );
    }

}

export default CategoryTreeView;
