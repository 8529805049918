import React, { Component } from "react";

import { TextBox } from 'devextreme-react/text-box';
import { Button, ValidationGroup } from 'devextreme-react';
import Validator, { RequiredRule, StringLengthRule } from 'devextreme-react/validator';
import { Accordion } from 'devextreme-react';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';

import ArticleDataSource from "./ArticleDataSource";
import ArticleSearchResultItem from "./ArticleSearchResultItem";

import "./ArticleSearch.css";

class ArticleSearch extends Component {

    findBoxRef = React.createRef();
    static historicalSearches = [];

    state = {
        loadIndicatorVisible: false,
        buttonText: "Search",
        searchResult: [{ articles: [] }],
        searchResultVisible: false,
        selectedItems: [],
    }

    static staticConstructor() {
        ArticleDataSource.connectionChangedEvent.addEventHandler(ArticleSearch.cacheCleanUp);
        ArticleDataSource.categoryRemovedEvent.addEventHandler(ArticleSearch.cacheCleanUp);
        ArticleDataSource.categoryChangedEvent.addEventHandler(ArticleSearch.cacheCleanUp);
    }

    static cacheCleanUp = () => {
        ArticleSearch.historicalSearches = [];
    }

    get findBox() {
        return this.findBoxRef.current.instance;
    }

    renderSearchResultTitle = (data) => {
        return (
            <h1 className="articleSearchResultTitle">Search Result</h1>
        );
    }

    renderSearchResultItems = (data) => {
        return (
            <div className="articleSearchResultContainer">
                {this.state.searchResult[0].articles.map(a => <ArticleSearchResultItem key={a.Id} id={a.Id} name={a.Name} count={a.Count} onSearchResultItemClick={this.props.navigateToArticleCallback} />)}
            </div>
        );
    }

    btnFindClick = (e) => {
        if (!e.validationGroup.validate().isValid) {
            return;
        }

        let textToFind = this.findBox._changedValue.toLowerCase();

        let prevResults = ArticleSearch.historicalSearches[textToFind];
        if (prevResults === undefined) {
            this.setState({ loadIndicatorVisible: true, buttonText: 'Searching' });
            ArticleDataSource.beginSearch(textToFind)
                .then((response) => {
                    let item = { articles: response.data.Articles };
                    this.setState({
                        searchResultVisible: true,
                        loadIndicatorVisible: false,
                        buttonText: 'Search',
                        searchResult: [item],
                        selectedItems: [item]
                    });
                    ArticleSearch.historicalSearches[textToFind] = response.data.Articles;
                })
                .catch((error) => {
                    this.setState({ loadIndicatorVisible: false, buttonText: 'Search' });
                    notify("Unable to execute search job.", "error", 2000);
                });
        } else {
            let item = { articles: prevResults };
            this.setState({ searchResult: [item], selectedItems: [item], searchResultVisible: true });
        }
    }

    selectionChanged = (e) => {
        let newItems = [...this.state.selectedItems];
        e.removedItems.forEach(item => {
            let index = newItems.indexOf(item);
            if (index >= 0) {
                newItems.splice(index, 1);
            }
        });
        if (e.addedItems.length) {
            newItems = [...newItems, ...e.addedItems];
        }
        this.setState({
            selectedItems: newItems
        });
    }

    render() {
        return (
            <section>
                <ValidationGroup>
                    <div className="articleSearchFlexContainer mb-3">
                        <div className="articleSearch bottomShadow">
                            <TextBox
                                ref={this.findBoxRef}
                                mode="search">
                                <Validator>
                                    <RequiredRule message={'Value is required'} />
                                    <StringLengthRule message="Value must have at least 3 symbols" min={3} />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className="ml-1">
                            <Button
                                width="10rem"
                                text="Find"
                                hint="Begin searching"
                                type="default"
                                stylingMode="contained"
                                disabled={this.state.loadIndicatorVisible}
                                onClick={this.btnFindClick}
                            >
                                <LoadIndicator className="button-indicator" visible={this.state.loadIndicatorVisible} />
                                <span className="dx-button-text">{this.state.buttonText}</span>
                            </Button>
                        </div>
                    </div>
                </ValidationGroup>
                <Accordion
                    className="bottomShadow articleSearchResultAfter"
                    dataSource={this.state.searchResult}
                    collapsible={true}
                    multiple={false}
                    animationDuration={300}
                    itemTitleRender={this.renderSearchResultTitle}
                    itemRender={this.renderSearchResultItems}
                    visible={this.state.searchResultVisible}
                    selectedItems={this.state.selectedItems}
                    onSelectionChanged={this.selectionChanged}
                />
            </section>
        );
    }

}
ArticleSearch.staticConstructor();

export default ArticleSearch;
