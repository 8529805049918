import React, { Component } from "react";
import { Redirect } from 'react-router';
import axios from "axios";

import { Button } from 'devextreme-react';
import { TextBox } from 'devextreme-react/text-box';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import { Popup } from 'devextreme-react/popup';
import CheckBox from 'devextreme-react/check-box';
import ValidationGroup from 'devextreme-react/validation-group';

import PageTitle from "../Auxilary/PageTitle";
import AuthContext from "../Context/Auth/AuthContext";
import { Urls } from "../../Urls";
import LoadingPopup from "../Popups/LoadingPopup";

import "./Login.css";

class Login extends Component {

    emailBoxRef = React.createRef();
    passwordBoxRef = React.createRef();

    state = {
        loginName: "",
        password: "",
        rememberMe: true,
        redirToForgotPass: false,
        isLoginFailedPopupVisible: false,
        loginSuccessRedirectURL: "",
        showLoading: false
    }

    componentDidMount() {
        PageTitle.setTitle("Sign In | " + PageTitle.defaultText);
        this.emailBox.focus();
    }

    usernameChangeHandler = (e) => {
        this.setState({ loginName: e.value });
    }

    passwordChangeHandler = (e) => {
        this.setState({ password: e.value });
    }

    cbRememberMeValueChanged = (e) => {
        this.setState({ rememberMe: e.value });
    }

    btnSignInClick = (e, authContext) => {
        if (!e.validationGroup.validate().isValid) {
            return;
        }

        this.setState({ showLoading: true });
        axios.post(Urls.PERFORM_LOGIN, { Username: this.state.loginName, Password: this.state.password, RememberMe: this.state.rememberMe })
            .then((response) => {
                //console.log(response);
                if (response.status === 200) {
                    if (response.data.Succeeded) {
                        let url = authContext.returnUrl.startsWith(Urls.ARTICLE) ? authContext.returnUrl : Urls.ARTICLE + "1";
                        this.setState({ loginSuccessRedirectURL: url, showLoading: false });
                        authContext.onChangeContextData({
                            isAuthenticated: true,
                            returnUrl: Urls.ARTICLE + "1",
                            userData: {
                                userId: response.data.UserId,
                                firstName: response.data.FirstName,
                                lastName: response.data.LastName,
                                email: response.data.EMail,
                                userType: response.data.UserType,
                                securityRights: response.data.SecurityRights,
                                isExternalUser: response.data.IsExternalUser
                            }
                        });
                    } else {
                        this.setState({ isLoginFailedPopupVisible: true, password: "", showLoading: false });
                    }
                } else {
                    this.setState({ isLoginFailedPopupVisible: true, password: "", showLoading: false });
                }
            }, (error) => {
                this.setState({ isLoginFailedPopupVisible: true, password: "", showLoading: false });
            })

        //e.validationGroup.reset();
    }

    btnForgotPassClick = () => {
        this.setState({ redirToForgotPass: true });
    }

    get emailBox() {
        return this.emailBoxRef.current.instance;
    }

    get passwordBox() {
        return this.passwordBoxRef.current.instance;
    }

    getLoginScreen = (authContext) => {
        return (
            <div className="centeredContextFlex">
                <ValidationGroup className="bottomShadow">
                    <div className="loginAfterMargin pLeft">
                        <p>Please enter your email:</p>
                        <TextBox
                            id="emlUN"
                            ref={this.emailBoxRef}
                            onValueChanged={this.usernameChangeHandler}
                            value={this.state.loginName}
                            hint="Your email address"
                            showClearButton={true}
                            width="15rem">
                            <Validator>
                                <RequiredRule message={'Email address is required'} />
                            </Validator>
                        </TextBox>
                    </div>
                    <div className="loginAfterMargin pLeft">
                        <p>Your password:</p>
                        <TextBox
                            id="pw"
                            onValueChanged={this.passwordChangeHandler}
                            value={this.state.password}
                            hint="Your password"
                            showClearButton={true}
                            mode="password"
                            width="15rem"
                            ref={this.passwordBoxRef}>
                            <Validator>
                                <RequiredRule message={'Password is required'} />
                            </Validator>
                        </TextBox>
                    </div>
                    <div className="loginAfterMargin pLeft">
                        <CheckBox defaultValue={true} text={'Remember me'} onValueChanged={this.cbRememberMeValueChanged} />
                    </div>
                    <div className="loginAfterMargin">
                        <Button
                            width="15rem"
                            text="Sign In"
                            hint="Proceed sign in"
                            type="default"
                            stylingMode="contained"
                            onClick={(e) => this.btnSignInClick(e, authContext)}
                        />
                    </div>
                    <hr />
                    <div className="loginAfterMargin">
                        <Button
                            width="15rem"
                            text="Forgot password"
                            hint="Go to the forgot password page"
                            type="default"
                            stylingMode="contained"
                            onClick={this.btnForgotPassClick}
                        />
                    </div>
                </ValidationGroup>
            </div>
        );
    }

    hideLoginFailedPopup = () => {
        this.setState({ isLoginFailedPopupVisible: false });
        this.passwordBox.focus();
    }

    render() {
        //console.log("Login.redirToForgotPass: " + this.state.redirToForgotPass);
        //console.log("Login.loginSuccessRedirectURL: " + this.state.loginSuccessRedirectURL);

        if (this.state.redirToForgotPass) {
            return <Redirect push to={Urls.FORGOT_PASSWORD} />;
        }

        if (this.state.loginSuccessRedirectURL !== "") {
            return <Redirect push to={this.state.loginSuccessRedirectURL} />;
        }

        return (
            <AuthContext.Consumer>
                {
                    authContext =>
                        <div className="scrollY height100 pt-4 pb-4">
                            {this.getLoginScreen(authContext)}
                            <Popup
                                visible={this.state.isLoginFailedPopupVisible}
                                onHiding={this.hideLoginFailedPopup}
                                dragEnabled={true}
                                closeOnOutsideClick={true}
                                showTitle={true}
                                title="Sign In"
                                width={300}
                                height={200}>
                                <div className="loginPopupCenter">
                                    <div className="centeredContent">
                                        <p className="pCenter">
                                            Sign in failed. Please try again.
                                        </p>
                                        <Button
                                            width="14.5rem"
                                            text="OK"
                                            type="default"
                                            stylingMode="contained"
                                            onClick={() => this.hideLoginFailedPopup()}
                                        />
                                    </div>
                                </div>
                            </Popup>
                            <LoadingPopup isVisible={this.state.showLoading} />
                        </div>
                }
            </AuthContext.Consumer>
        );
    }

}

export default Login;
