import React, { Component, Fragment } from "react";
import { Redirect } from 'react-router';
import axios from "axios";

import { Button } from 'devextreme-react';
import { TextBox } from 'devextreme-react/text-box';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import ValidationGroup from 'devextreme-react/validation-group';
import { TextArea } from 'devextreme-react/text-area';
import { Popup } from 'devextreme-react/popup';

import PageTitle from "../Auxilary/PageTitle";
import { Urls } from "../../Urls";
import AuthContext from "../Context/Auth/AuthContext";
import LoadingPopup from "../Popups/LoadingPopup";

import "./Contact.css";

class Contact extends Component {

    subjectBoxRef = React.createRef();

    state = {
        subject: "",
        message: "",
        isFailedPopupVisible: false,
        isSuccessPopupVisible: false,
        showLoading: false
    }

    componentDidMount() {
        PageTitle.setTitle("Contact us | " + PageTitle.defaultText);
        var subjbox = this.subjectBox;
        if (subjbox !== null) subjbox.focus();
    }

    subjectChangeHandler = (e) => {
        this.setState({ subject: e.value });
    }

    messageChangeHandler = (e) => {
        this.setState({ message: e.value });
    }

    get subjectBox() {
        return this.subjectBoxRef.current == null ? null : this.subjectBoxRef.current.instance;
    }

    btnSendClick = (e) => {
        if (!e.validationGroup.validate().isValid) {
            return;
        }

        this.setState({ showLoading: true });
        axios.post(Urls.CONTACT_PERFORM,
            {
                Subject: this.state.subject,
                Message: this.state.message
            })
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.Succeeded) {
                        e.validationGroup.reset();
                        this.setState({ isSuccessPopupVisible: true, showLoading: false });
                    } else {
                        this.setState({ isFailedPopupVisible: true, showLoading: false });
                    }
                } else {
                    this.setState({ isFailedPopupVisible: true, showLoading: false });
                }
            }, (error) => {
                    this.setState({ isFailedPopupVisible: true, showLoading: false });
            })

    }

    renderContactForm = () => {
        return (
            <div className="scrollY height100 pt-4 pb-4">
                <div className="centeredContextFlex">
                    <ValidationGroup className="bottomShadow">
                        <p className="pHeader contactAfterMargin pLeft">Send us a message</p>
                        <div className="contactAfterMargin pLeft">
                            <p>Subject</p>
                            <TextBox
                                ref={this.subjectBoxRef}
                                onValueChanged={this.subjectChangeHandler}
                                value={this.state.subject}
                                hint="Subject of your message"
                                showClearButton={true}
                                maxLength={128}
                                width="15rem">
                                <Validator>
                                    <RequiredRule message={'Subject is required'} />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className="contactAfterMargin pLeft">
                            <p>Message:</p>
                            <TextArea
                                onValueChanged={this.messageChangeHandler}
                                value={this.state.message}
                                hint="Your message"
                                showClearButton={true}
                                maxLength={2048}
                                width="15rem"
                                height="5rem">
                                <Validator>
                                    <RequiredRule message={'Message is required'} />
                                </Validator>
                            </TextArea>
                        </div>
                        <div className="contactAfterMargin">
                            <Button
                                width="15rem"
                                text="Send"
                                hint="Send your message"
                                type="default"
                                stylingMode="contained"
                                onClick={(e) => this.btnSendClick(e)}
                            />
                        </div>
                    </ValidationGroup>
                </div>
            </div>
        );
    }

    hideFailedPopup = () => {
        this.setState({ isFailedPopupVisible: false });
    }

    hideSuccessPopup = () => {
        this.setState({ isSuccessPopupVisible: false });
    }

    render() {
        return (
            <AuthContext.Consumer>
                {
                    authContext =>
                        <Fragment>
                            {authContext.isAuthenticated ?
                                this.renderContactForm() : <Redirect push to={Urls.LANDING} />}
                            <Popup
                                visible={this.state.isFailedPopupVisible}
                                onHiding={this.hideFailedPopup}
                                dragEnabled={true}
                                closeOnOutsideClick={true}
                                showTitle={true}
                                title="Contact us"
                                width={300}
                                height={250}>
                                <div className="contactPopupCenter">
                                    <div className="centeredContent">
                                        <p className="pCenter">
                                            We are sorry.<br />Your message has not been sent.<br />Please try again later.
                                        </p>
                                        <Button
                                            width="14.5rem"
                                            text="OK"
                                            type="default"
                                            stylingMode="contained"
                                            onClick={() => this.hideFailedPopup()}
                                        />
                                    </div>
                                </div>
                            </Popup>
                            <Popup
                                visible={this.state.isSuccessPopupVisible}
                                onHiding={this.hideSuccessPopup}
                                dragEnabled={true}
                                closeOnOutsideClick={true}
                                showTitle={true}
                                title="Contact us"
                                width={300}
                                height={200}>
                                <div className="contactPopupCenter">
                                    <div className="centeredContent">
                                        <p className="pCenter">
                                            Thank you for your message.<br />We will contact you as soon as possible.
                                        </p>
                                        <Button
                                            width="14.5rem"
                                            text="OK"
                                            type="default"
                                            stylingMode="contained"
                                            onClick={() => this.hideSuccessPopup()}
                                        />
                                    </div>
                                </div>
                            </Popup>
                            <LoadingPopup isVisible={this.state.showLoading} />
                        </Fragment>
                }
            </AuthContext.Consumer>
        );
    }

}

export default Contact;
