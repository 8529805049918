import React, { Component, Fragment } from "react";
import { Redirect } from 'react-router';
import axios from "axios";

import CheckBox from 'devextreme-react/check-box';
import { Button } from 'devextreme-react';
import { TextBox } from 'devextreme-react/text-box';
import Validator, { RequiredRule, EmailRule, CustomRule, StringLengthRule } from 'devextreme-react/validator';
import { Popup } from 'devextreme-react/popup';
import ValidationGroup from 'devextreme-react/validation-group';
import notify from 'devextreme/ui/notify';

import PageTitle from "../Auxilary/PageTitle";
import AuthContext from "../Context/Auth/AuthContext";
import { Urls } from "../../Urls";
import LoadingPopup from "../Popups/LoadingPopup";

import "./Register.css";

class Register extends Component {

    userEmailBoxRef = React.createRef();
    password1BoxRef = React.createRef();
    password2BoxRef = React.createRef();

    state = {
        loginName: "",
        firstName: "",
        lastName: "",
        password1: "",
        password2: "",
        isProfUser: true,
        canAdminArticle: false,
        isRegFailedPopupVisible: false,
        showPasswordReqInfo: false,
        showLoading: false
    }

    get userEmailBox() {
        return this.userEmailBoxRef.current.instance;
    }

    get password1Box() {
        return this.password1BoxRef.current.instance;
    }

    get password2Box() {
        return this.password2BoxRef.current.instance;
    }

    componentDidMount() {
        PageTitle.setTitle("Register a new user | " + PageTitle.defaultText);
    }

    usernameChangeHandler = (e) => {
        this.setState({ loginName: e.value });
    }

    firstNameChangeHandler = (e) => {
        this.setState({ firstName: e.value });
    }

    lastNameChangeHandler = (e) => {
        this.setState({ lastName: e.value });
    }

    password1ChangeHandler = (e) => {
        this.setState({ password1: e.value });
    }

    password2ChangeHandler = (e) => {
        this.setState({ password2: e.value });
    }

    passwordRequirementsCallback = (e) => {
        var result = this.state.password1.length >= 8;
        if (result) result = this.state.password1.search("[0-9]") !== -1;
        if (result) result = this.state.password1.search("[a-z]") !== -1;
        if (result) result = this.state.password1.search("[A-Z]") !== -1;
        if (!result) this.setState({ showPasswordReqInfo: true });
        return result;
    }

    passwordComparatorCallback = (e) => {
        return this.state.password1 === this.state.password2;
    }

    cbAdminArticleValueChanged = (e) => {
        this.setState({ canAdminArticle: e.value });
    }

    cbProfUserValueChanged = (e) => {
        this.setState({ isProfUser: e.value });
    }

    btnRegisterClick = (e, authContext) => {
        if (!e.validationGroup.validate().isValid) {
            return;
        }

        this.setState({ showLoading: true });
        axios.post(Urls.REGISTER_USER,
            {
                FirstName: this.state.firstName,
                LastName: this.state.lastName,
                Username: this.state.loginName,
                Password: this.state.password1,
                ConfirmPassword: this.state.password2,
                IsProfessionalUser: this.state.isProfUser,
                CanAdminArticles: this.state.canAdminArticle
            })
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.Succeeded) {
                        e.validationGroup.reset();
                        this.setState({ showPasswordReqInfo: false, showLoading: false });
                        notify("User sucessfully registered", "success", 1000);
                        this.userEmailBox.focus();
                    } else {
                        this.setState({ isRegFailedPopupVisible: true, showLoading: false });
                    }
                } else {
                    this.setState({ isRegFailedPopupVisible: true, showLoading: false });
                }
            }, (error) => {
                    this.setState({ isRegFailedPopupVisible: true, showLoading: false });
            })

    }

    getPasswordReqInfo = () => {
        return (
            <div className="divPassReqCenterPanel">
                <div className="centeredContent">
                    <div className="divPassReqPanel">
                        <p>Password requirements:</p>
                        <ul>
                            <li>Minimum 8 characters</li>
                            <li>Alphabets: [a-z]</li>
                            <li>Alphabets: [A-Z]</li>
                            <li>Numbers: [0-9]</li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    renderRegistrationForm = (authContext) => {
        /*
            <PatternRule
                pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
                message="Please enter valid email address"
            />
        */
        return (
            <div className="scrollY height100 pt-4 pb-4">
                <div className="centeredContextFlex">
                    <ValidationGroup className="bottomShadow">
                        <p className="pHeader regAfterMargin pLeft">Register a new user</p>
                        <div className="regAfterMargin pLeft">
                            <p>Please enter user's email</p>
                            <TextBox
                                id="usrEml"
                                ref={this.userEmailBoxRef}
                                onValueChanged={this.usernameChangeHandler}
                                value={this.state.loginName}
                                hint="User's email address"
                                showClearButton={true}
                                width="15rem">
                                <Validator>
                                    <RequiredRule message={'Email is required'} />
                                    <EmailRule message="Please enter valid email address" />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className="regAfterMargin pLeft">
                            <p>First name</p>
                            <TextBox
                                id="frstName"
                                onValueChanged={this.firstNameChangeHandler}
                                value={this.state.firstName}
                                hint="First name"
                                showClearButton={true}
                                width="15rem">
                                <Validator>
                                    <RequiredRule message={'First name is required'} />
                                    <StringLengthRule message="First name must have at least 2 symbols" min={2} />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className="regAfterMargin pLeft">
                            <p>Last name</p>
                            <TextBox
                                id="lstName"
                                onValueChanged={this.lastNameChangeHandler}
                                value={this.state.lastName}
                                hint="Last name"
                                showClearButton={true}
                                width="15rem">
                                <Validator>
                                    <RequiredRule message={'Last name is required'} />
                                    <StringLengthRule message="Last name must have at least 2 symbols" min={2} />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className="regAfterMargin pLeft">
                            <p>User's password</p>
                            <TextBox
                                id="pw1"
                                onValueChanged={this.password1ChangeHandler}
                                value={this.state.password1}
                                hint="Password"
                                showClearButton={true}
                                mode="password"
                                width="15rem"
                                ref={this.password1BoxRef}>
                                <Validator>
                                    <RequiredRule message={'Password is required'} />
                                    <CustomRule
                                        message="Password does not meet the requirements."
                                        validationCallback={this.passwordRequirementsCallback}
                                        reevaluate={true}
                                    />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className="regAfterMargin pLeft">
                            <p>Confirm user's password</p>
                            <TextBox
                                id="pw2"
                                onValueChanged={this.password2ChangeHandler}
                                value={this.state.password2}
                                hint="Password"
                                showClearButton={true}
                                mode="password"
                                width="15rem"
                                ref={this.password2BoxRef}>
                                <Validator>
                                    <RequiredRule message={'Password is required'} />
                                    <CustomRule
                                        message="Passwords are not the same"
                                        validationCallback={this.passwordComparatorCallback}
                                        reevaluate={true}
                                    />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className="regAfterMargin pLeft">
                            <CheckBox defaultValue={false} text={'Can admin articles'} onValueChanged={this.cbAdminArticleValueChanged} />
                        </div>
                        <div className="regAfterMargin pLeft">
                            <CheckBox defaultValue={true} text={'Supporter user'} onValueChanged={this.cbProfUserValueChanged} />
                        </div>
                        <div className="regAfterMargin">
                            <Button
                                width="15rem"
                                text="Register"
                                hint="Register a new user"
                                type="default"
                                stylingMode="contained"
                                onClick={(e) => this.btnRegisterClick(e, authContext)}
                            />
                        </div>
                    </ValidationGroup>
                </div>
            </div>
        );
    }

    hideRegFailedPopup = () => {
        this.setState({ isRegFailedPopupVisible: false });
    }

    render() {
        return (
            <AuthContext.Consumer>
                {
                    authContext =>
                        <Fragment>
                            {authContext.isAuthenticated ?
                                this.renderRegistrationForm(authContext) : <Redirect push to={Urls.LANDING} />}
                            <Popup
                                visible={this.state.isRegFailedPopupVisible}
                                onHiding={this.hideRegFailedPopup}
                                dragEnabled={true}
                                closeOnOutsideClick={true}
                                showTitle={true}
                                title="Registration"
                                width={300}
                                height={200}>
                                <div className="regPopupCenter">
                                    <div className="centeredContent">
                                        <p className="pCenter">
                                            Registration failed.<br />Contact system administrator.
                                        </p>
                                        <Button
                                            width="14.5rem"
                                            text="OK"
                                            type="default"
                                            stylingMode="contained"
                                            onClick={() => this.hideRegFailedPopup()}
                                        />
                                    </div>
                                </div>
                            </Popup>
                            {this.state.showPasswordReqInfo ? this.getPasswordReqInfo() : null}
                            <LoadingPopup isVisible={this.state.showLoading} />
                        </Fragment>
                }
            </AuthContext.Consumer>
        );
    }

}

export default Register;
