import React, { Component } from "react";

import "./ArticleSearchResultItem.css";

export default class ArticleSearchResultItem extends Component {

    render() {
        return (
            <div className="asResultContainer" onClick={() => this.props.onSearchResultItemClick(this.props.id)}>
                <div className="centeredContextFlex pLeft"><p>Count: {this.props.count}.</p></div>
                <div className="centeredContextFlex pLeft noWrapText"><p>{this.props.name}</p></div>
            </div>
        );
    }

}
