import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Switch } from "react-router-dom";

import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import AdminUsers from "./components/Auth/AdminUsers";
import ConfirmEmail from "./components/Auth/ConfirmEmail";
import ForgotPassword from "./components/Auth/ForgotPassword";
import Contact from "./components/Contact/Contact";
import Article from "./components/Article/ArticleHOC";
import LandingPage from "./components/LandingPage";
import UserProfileHOC from "./components/Auth/UserProfileHOC";
import ResetPassword from "./components/Auth/ResetPassword";
import { Urls } from "./Urls";

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

class App extends Component {
    render() {
        return (
            <Layout>
                <Switch>
                    <Route exact path={Urls.LANDING} component={LandingPage} />
                    <Route exact path={Urls.LOGIN} component={Login} />
                    <Route exact path={Urls.REGISTER_USER} component={Register} />
                    <Route exact path={Urls.ADMIN_USERS} component={AdminUsers} />
                    <Route exact path={Urls.CONFIRM_EMAIL} component={ConfirmEmail} />
                    <Route exact path={Urls.USER_PROFILE} component={UserProfileHOC} />
                    <Route exact path={Urls.FORGOT_PASSWORD} component={ForgotPassword} />
                    <Route exact path={Urls.CONTACT} component={Contact} />
                    <Route path={Urls.ARTICLE + ':id'} component={Article} />
                    <Route path={Urls.RESET_PASSWORD} component={ResetPassword} />
                    <Route render={() => <h3 style={{ textAlign: 'center' }}>We are sorry. The requested content not found. Please check your URL.</h3>} />
                </Switch>
            </Layout>
        );
    }
}

export default App;
