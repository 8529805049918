import React, { Component, Fragment } from "react";

import { TextArea, TextBox, FileUploader, Button, ValidationGroup, RadioGroup } from 'devextreme-react';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import { Popup } from 'devextreme-react/popup';
import notify from 'devextreme/ui/notify';
import CheckBox from 'devextreme-react/check-box';
import HtmlEditor, { Toolbar, Item, MediaResizing } from 'devextreme-react/html-editor';

import PageTitle from "../Auxilary/PageTitle";
import ArticleContext from "../Context/Article/ArticleContext";
import ArticleDataSource from "./ArticleDataSource";
import ArticleResourceItem from "./ArticleResourceItem";
import { Urls } from "../../Urls";
import LoadingPopup from "../Popups/LoadingPopup";
import { StringBuilder } from "../../libs/AuxLibs";

import "./ArticleEditor.css";

class ArticleEditor extends Component {

    articleNameBoxRef = React.createRef();
    popupResourceUrlBoxRef = React.createRef();

    sizeValues = ['0.9rem', '6px', '8px', '10px', '11px', '12px', '14px', '16px', '18px', '22px', '24px', '30px', '36px', '42px', '56px', '72px'];
    fontValues = ['Arial', 'Courier New', 'Georgia', 'Impact', 'Merriweather', 'Lucida Console', 'serif', 'Tahoma', 'Times New Roman', 'Verdana'];
    headerValues = [false, 1, 2, 3, 4, 5, 6];
    mediaTypes = ['Video', 'Audio'];

    state = {
        articleId: this.props.articleContext.articleData.Id,
        articleName: this.props.articleContext.header,
        isVisible: this.props.articleContext.isVisible,
        isCommentsAllowed: this.props.articleContext.isCommentsAllowed,
        content: this.props.articleContext.content,
        contentInHtml: "",
        markUpPopupVisible: false,
        insertMediaPopupVisible: false,
        popupResourceUrl: "",
        popupResourceIsResponsiveMediaSize: true,
        popupResourceWidth: "320",
        popupResourceHeight: "",
        popupResourceControls: true,
        popupResourceAutoplay: false,
        popupResourceBottomShadow: true,
        popupSelectedMediaType: this.mediaTypes[0],
        showLoading: false
    }

    get articleNameBox() {
        return this.articleNameBoxRef.current.instance;
    }

    get popupResourceUrlBox() {
        return this.popupResourceUrlBoxRef.current.instance;
    }

    componentDidMount() {
        PageTitle.setTitle("Edit Article | " + PageTitle.defaultText);
        this.articleNameBox.focus();
    }

    articleNameChangeHandler = (e) => {
        this.setState({ articleName: e.value });
    }

    cbIsVisibleValueChanged = (e) => {
        this.setState({ isVisible: e.value });
    }

    cbIsCommentsAllowedValueChanged = (e) => {
        this.setState({ isCommentsAllowed: e.value });
    }

    htmlEditorValueChanged = (e) => {
        this.setState({
            content: e.value
        });
    }

    showMarkUpPopup = (e) => {
        this.setState((prevState, props) => ({
            markUpPopupVisible: true, contentInHtml: prevState.content
        }));
    }

    markUpPopupHiding = () => {
        this.setState({ markUpPopupVisible: false });
    }

    markUpPopupHidingWithApplyChanges = () => {
        this.setState((prevState, props) => ({
            markUpPopupVisible: false, content: prevState.contentInHtml
        }));
    }

    showInsertMediaPopup = (e) => {
        this.setState({
            insertMediaPopupVisible: true,
            popupResourceUrl: "",
            popupResourceIsResponsiveMediaSize: true,
            popupResourceWidth: "320",
            popupResourceHeight: "",
            popupResourceControls: true,
            popupResourceAutoplay: false,
            popupResourceBottomShadow: true,
            popupSelectedMediaType: this.mediaTypes[0]
        });
        this.popupResourceUrlBox.focus();
    }

    insertMediaPopupHiding = () => {
        this.setState({ insertMediaPopupVisible: false });
    }

    insertMediaPopupHidingWithApplyChanges = (e) => {
        if (!e.validationGroup.validate().isValid) {
            return;
        }

        let { popupResourceUrl,
            popupResourceIsResponsiveMediaSize,
            popupResourceWidth,
            popupResourceHeight,
            popupResourceControls,
            popupResourceAutoplay,
            popupResourceBottomShadow,
            popupSelectedMediaType } = this.state;
        let sb = new StringBuilder();

        if (popupSelectedMediaType === "Video") {
            sb.append("<video");
        } else {
            sb.append("<audio");
        }

        if (popupResourceBottomShadow || popupResourceIsResponsiveMediaSize) {
            sb.append(" class=\"");
            if (popupResourceBottomShadow) sb.append("bottomShadow ");
            if (popupResourceIsResponsiveMediaSize) sb.append("video");
            sb.append("\"");
        }

        if (!popupResourceIsResponsiveMediaSize && (popupResourceWidth !== "" || popupResourceHeight !== "")) {
            sb.append(" style=\"");
            if (popupResourceWidth !== "") {
                sb.append("width:");
                sb.append(popupResourceWidth);
                sb.append("px");
            }
            if (popupResourceHeight !== "") {
                sb.append("height:");
                sb.append(popupResourceHeight);
                sb.append("px");
            }
            sb.append("\"");
        }

        if (popupResourceWidth !== "") {
            sb.append(" width=\"");
            sb.append(popupResourceWidth);
            sb.append("\"");
        }

        if (popupResourceHeight !== "") {
            sb.append(" height=\"");
            sb.append(popupResourceHeight);
            sb.append("\"");
        }

        if (popupResourceControls) {
            sb.append(" controls");
        }

        if (popupResourceAutoplay) {
            sb.append(" autoplay");
        }

        sb.append(">");
        sb.append("<source src=\"");
        sb.append(popupResourceUrl);
        sb.append("\" type=\"");
        if (popupResourceUrl.toLowerCase().endsWith("mp4")) {
            sb.append("video/mp4\">");
        } else if (popupResourceUrl.toLowerCase().endsWith("ogg")) {
            if (popupSelectedMediaType === "Video") {
                sb.append("video/");
            } else {
                sb.append("audio/");
            }
            sb.append("ogg\">");
        } else if (popupResourceUrl.toLowerCase().endsWith("webm")) {
            sb.append("video/webm\">");
        } else if (popupResourceUrl.toLowerCase().endsWith("mp3")) {
            sb.append("audio/mpeg\">");
        } else if (popupResourceUrl.toLowerCase().endsWith("wav")) {
            sb.append("audio/wav\">");
        } else {
            sb.append("\">");
        }

        if (popupSelectedMediaType === "Video") {
            sb.append("(Your browser does not support the video element or you are in editor mode. If you are editing this page, you will see the result, after the article saved.)");
            sb.append("</video");
        } else {
            sb.append("(Your browser does not support the audio element or you are in editor mode. If you are editing this page, you will see the result, after the article saved.)");
            sb.append("</audio");
        }

        this.setState((prevState, props) => ({
            insertMediaPopupVisible: false, content: prevState.content + sb.toString()
        }));
    }

    htmlCodeChangeHandler = (e) => {
        this.setState({ contentInHtml: e.value });
    }

    removeResource = (id) => {
        this.setState({ showLoading: true });
        ArticleDataSource.removeResource(this.props.articleContext.articleData.Id, id)
            .then((result) => {
                this.setState({ showLoading: false });
                notify("Resource file removed.", "success", 1000);
            })
            .catch((error) => {
                this.setState({ showLoading: false });
                notify("Failed to remove the resource file.", "error", 1000);
            });
    }

    commitArticleChanges = (e) => {
        if (!e.validationGroup.validate().isValid) {
            return;
        }

        this.setState({ showLoading: true });
        ArticleDataSource.modifyArticle(
            this.state.articleId,
            this.state.articleName,
            this.state.content,
            this.state.isVisible,
            this.state.isCommentsAllowed
        )
            .then((result) => {
                this.props.articleContext.onArticleEditModeChange(false);
                //this.setState({ showLoading: false });
                notify("Article successfully updated.", "success", 1000);
            })
            .catch((error) => {
                this.setState({ showLoading: false });
                notify("Unable to save article.", "error", 3000);
            });

    }

    discardArticleChanges = () => {
        this.props.articleContext.onArticleEditModeChange(false);
    }

    toolbarHtmlButtonOptions = {
        text: 'Edit HTML Code',
        stylingMode: 'text',
        onClick: this.showMarkUpPopup
    };

    toolbarInsertMediaButtonOptions = {
        text: 'Media',
        stylingMode: 'text',
        onClick: this.showInsertMediaPopup
    };

    popupResourceUrlChangeHandler = (e) => {
        this.setState({ popupResourceUrl: e.value });
    }

    popupResourceWidthChangeHandler = (e) => {
        this.setState({ popupResourceWidth: e.value });
    }

    popupResourceHeightChangeHandler = (e) => {
        this.setState({ popupResourceHeight: e.value });
    }

    cbMediaResponsiveSizingValueChanged = (e) => {
        this.setState({ popupResourceIsResponsiveMediaSize: e.value });
    }

    cbShowControlValueChanged = (e) => {
        this.setState({ popupResourceControls: e.value });
    }

    cbAutoplayValueChanged = (e) => {
        this.setState({ popupResourceAutoplay: e.value });
    }

    cbDisplayBottomShadowValueChanged = (e) => {
        this.setState({ popupResourceBottomShadow: e.value });
    }

    renderHeader() {
        return (
            <section className="editorAfterMarginEnd pLeft bottomShadow">
                <h1>Article Name</h1>
                <p>Enter a short and informative name for the article:</p>
                <TextBox
                    ref={this.articleNameBoxRef}
                    onValueChanged={this.articleNameChangeHandler}
                    value={this.state.articleName}
                    hint="Article name"
                    showClearButton={true}
                    width="15rem">
                    <Validator>
                        <RequiredRule message={'Article name is required'} />
                    </Validator>
                </TextBox>
                <CheckBox defaultValue={this.state.isVisible} text={'Article is published (visible)'} onValueChanged={this.cbIsVisibleValueChanged} />
                <br />
                <CheckBox className="editorAfterMargin" defaultValue={this.state.isCommentsAllowed} text={'Comments are enabled'} onValueChanged={this.cbIsCommentsAllowedValueChanged} />
            </section>
        );
    }

    renderResources(articleContext) {
        return (
            <section className="editorAfterMarginEnd pLeft bottomShadow">
                <h1>Resources</h1>
                <p>
                    Here you can upload to the server images, videos, other files, which will be linked and accessed from the Article.
                    Every resource item will have an own and unique URL, which can be used in the HTML editor below.
                </p>
                <div className="editorResScrollableContainer">
                    <div className="editorGridContainer">
                        <div className="editorBorderBottom leftMiddleContentFlex"><p className="editorResHeaderText parag">File name</p></div>
                        <div className="editorBorderBottom leftMiddleContentFlex"><p className="editorResHeaderText parag">File size (bytes)</p></div>
                        <div className="editorBorderBottom"></div>
                        <div className="editorBorderBottom"></div>
                        {articleContext.resources.map(r => <ArticleResourceItem key={r.Id} id={r.Id} articleId={articleContext.articleData.Id} fileName={r.FileName} fileSize={r.FileSize} onResourceRemove={this.removeResource} />)}
                    </div>
                </div>
                <div className="editorFileUploaderContainer">
                    <FileUploader
                        name="ArticleEditor"
                        multiple={true}
                        accept="*"
                        uploadMode="instantly"
                        uploadUrl={Urls.ARTICLE_API_POST_FILEUPLOAD + articleContext.articleData.Id}
                        onValueChanged={this.onSelectedFilesChanged}
                        chunkSize={100000}
                    />
                </div>
            </section>
        );
    }

    renderHtmlEditor() {
        return (
            <section className="editorAfterMargin pLeft bottomShadow">
                <h1>Content</h1>
                <p>
                    Edit the content of the article. If you would like to insert an image/video/downloadable content from the resources,
                    please use the 'copy' button next to the resource and paste the given link into the right place.
                </p>
                <HtmlEditor
                    height={800}
                    onValueChanged={this.htmlEditorValueChanged}
                    valueType="html"
                    mediaResizing={{ enabled: true }}
                    value={this.state.content}
                >
                    <MediaResizing enabled={true} />
                    <Toolbar>
                        <Item formatName="undo" />
                        <Item formatName="redo" />
                        <Item formatName="separator" />
                        <Item
                            formatName="size"
                            formatValues={this.sizeValues}
                        />
                        <Item
                            formatName="font"
                            formatValues={this.fontValues}
                        />
                        <Item formatName="separator" />
                        <Item formatName="bold" />
                        <Item formatName="italic" />
                        <Item formatName="strike" />
                        <Item formatName="underline" />
                        <Item formatName="separator" />
                        <Item formatName="alignLeft" />
                        <Item formatName="alignCenter" />
                        <Item formatName="alignRight" />
                        <Item formatName="alignJustify" />
                        <Item formatName="separator" />
                        <Item
                            formatName="header"
                            formatValues={this.headerValues}
                        />
                        <Item formatName="separator" />
                        <Item formatName="orderedList" />
                        <Item formatName="bulletList" />
                        <Item formatName="separator" />
                        <Item formatName="decreaseIndent" />
                        <Item formatName="increaseIndent" />
                        <Item formatName="separator" />
                        <Item formatName="color" />
                        <Item formatName="background" />
                        <Item formatName="separator" />
                        <Item formatName="link" />
                        <Item formatName="image" />
                        <Item
                            widget="dxButton"
                            options={this.toolbarInsertMediaButtonOptions}
                        />
                        <Item formatName="separator" />
                        <Item formatName="clear" />
                        <Item formatName="codeBlock" />
                        <Item formatName="blockquote" />
                        <Item formatName="subscript" />
                        <Item formatName="superscript" />
                        <Item formatName="separator" />
                        <Item
                            widget="dxButton"
                            options={this.toolbarHtmlButtonOptions}
                        />
                    </Toolbar>
                </HtmlEditor>
                <Popup
                    showTitle={true}
                    title="Edit HTML Code"
                    visible={this.state.markUpPopupVisible}
                    onHiding={this.markUpPopupHiding}
                    dragEnabled={true}
                    closeOnOutsideClick={true}
                    resizeEnabled={true}
                >
                    <div className="editorHtmlPopupContainer">
                        <TextArea
                            width="100%"
                            height="100%"
                            hint="Edit the HTML code for the article"
                            showClearButton={false}
                            onValueChanged={this.htmlCodeChangeHandler}
                            value={this.state.contentInHtml}>
                        </TextArea>
                        <div className="pRight pt-1 mr-2">
                            <Button
                                width="7rem"
                                text="OK"
                                type="default"
                                stylingMode="contained"
                                onClick={this.markUpPopupHidingWithApplyChanges}
                            />
                            <div className="editorButtonsGap"></div>
                            <Button
                                width="7rem"
                                text="Cancel"
                                type="danger"
                                stylingMode="contained"
                                onClick={this.markUpPopupHiding}
                            />
                        </div>
                    </div>
                </Popup>
                <Popup
                    showTitle={true}
                    title="Insert Media"
                    visible={this.state.insertMediaPopupVisible}
                    onHiding={this.insertMediaPopupHiding}
                    dragEnabled={true}
                    closeOnOutsideClick={true}
                    resizeEnabled={false}
                    width={400}
                    height={420}
                >
                    <div className="editorHtmlPopupContainer">
                        <ValidationGroup>
                            <div className="editorPopupGridContainer">
                                <div><p>Media type</p></div>
                                <div>
                                    <RadioGroup items={this.mediaTypes} defaultValue={this.mediaTypes[0]} value={this.state.popupSelectedMediaType} />
                                </div>
                                <div><p>URL</p></div>
                                <div>
                                    <TextBox
                                        ref={this.popupResourceUrlBoxRef}
                                        onValueChanged={this.popupResourceUrlChangeHandler}
                                        value={this.state.popupResourceUrl}
                                        hint="Resource URL"
                                        showClearButton={true}
                                        width="100%">
                                        <Validator>
                                            <RequiredRule message={'Url is required'} />
                                        </Validator>
                                    </TextBox>
                                </div>
                                <div></div>
                                <div>
                                    <CheckBox value={true} text={'Responsive Media Sizing'} hint="The media size will fit to the available space on the UI" onValueChanged={this.cbMediaResponsiveSizingValueChanged} />
                                </div>
                                <div><p>Width</p></div>
                                <div>
                                    <TextBox
                                        onValueChanged={this.popupResourceWidthChangeHandler}
                                        value={this.state.popupResourceWidth}
                                        disabled={this.state.popupResourceIsResponsiveMediaSize}
                                        hint="Width"
                                        showClearButton={true}
                                        width="100%"
                                        mask="0000">
                                    </TextBox>
                                </div>
                                <div><p>Height</p></div>
                                <div>
                                    <TextBox
                                        onValueChanged={this.popupResourceHeightChangeHandler}
                                        value={this.state.popupResourceHeight}
                                        disabled={this.state.popupResourceIsResponsiveMediaSize}
                                        hint="Height"
                                        showClearButton={true}
                                        width="100%"
                                        mask="0000">
                                    </TextBox>
                                </div>
                                <div></div>
                                <div>
                                    <CheckBox value={true} text={'Show controls'} hint="Show controls on the player" onValueChanged={this.cbShowControlValueChanged} />
                                </div>
                                <div></div>
                                <div>
                                    <CheckBox value={false} text={'Autoplay'} hint="The autoplay attribute does not work in mobile devices like iPad and iPhone." onValueChanged={this.cbAutoplayValueChanged} />
                                </div>
                                <div></div>
                                <div>
                                    <CheckBox value={true} text={'Display shadow in the bottom'} hint="Display a nice shadow at the bottom of the video." onValueChanged={this.cbDisplayBottomShadowValueChanged} />
                                </div>
                            </div>
                            <div className="centeredContextFlex flexDirRow pt-1 mr-2">
                                <Button
                                    width="7rem"
                                    text="OK"
                                    type="default"
                                    stylingMode="contained"
                                    onClick={this.insertMediaPopupHidingWithApplyChanges}
                                />
                                <div className="editorButtonsGap"></div>
                                <Button
                                    width="7rem"
                                    text="Cancel"
                                    type="danger"
                                    stylingMode="contained"
                                    onClick={this.insertMediaPopupHiding}
                                />
                            </div>
                        </ValidationGroup>
                    </div>
                </Popup>
            </section>
        );
    }

    renderButtonsTop() {
        return (
            <div className="pLeft editorAfterMargin mr-2">
                <Button
                    width="9rem"
                    text="Save"
                    type="default"
                    stylingMode="contained"
                    onClick={this.commitArticleChanges}
                />
                <div className="editorButtonsGap"></div>
                <Button
                    width="9rem"
                    text="Cancel"
                    type="danger"
                    stylingMode="contained"
                    onClick={this.discardArticleChanges}
                />
            </div>
        );
    }

    renderButtonsBottom() {
        return (
            <div className="pLeft pt-2 pb-2 mb-5 mr-2">
                <Button
                    width="9rem"
                    text="Save"
                    type="default"
                    stylingMode="contained"
                    onClick={this.commitArticleChanges}
                />
                <div className="editorButtonsGap"></div>
                <Button
                    width="9rem"
                    text="Cancel"
                    type="danger"
                    stylingMode="contained"
                    onClick={this.discardArticleChanges}
                />
            </div>
        );
    }

    render() {
        return (
            <ArticleContext.Consumer>
                {
                    articleContext =>
                        <Fragment>
                            {this.renderButtonsTop()}
                            {this.renderHeader()}
                            {this.renderResources(articleContext)}
                            {this.renderHtmlEditor()}
                            {this.renderButtonsBottom()}
                            <LoadingPopup isVisible={this.state.showLoading} />
                        </Fragment>
                }
            </ArticleContext.Consumer>
        );
    }

}

export default ArticleEditor;
