import React, { Component } from "react";

import Article from "./Article";
import AuthContext from "../Context/Auth/AuthContext";

class ArticleHOC extends Component {

    render() {
        return (
            <AuthContext.Consumer>
                {
                    authContext =>
                        <Article authContext={authContext} {...this.props} />
                }
            </AuthContext.Consumer>
        );
    }

}

export default ArticleHOC;
