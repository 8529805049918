import React from "react";
import PropTypes from "prop-types";

const articleContext = React.createContext({
    isArticleEditMode: false,
    isCategoryEditMode: false,

    articleData: {},
    header: "",
    content: "",
    resources: [],
    comments: [],
    modifiedByName: "",
    isVisible: false,
    isCommentsAllowed: false,
    treeListFocusedRow: -1,

    categories: [],
    visibleCategories: [],

    onCategoryChange: (category) => { },
    onCategoryEditModeChange: (isEdit) => { },
    onArticleEditModeChange: (isEdit) => { }
});

articleContext.propTypes = {
    isArticleEditMode: PropTypes.bool,
    isCategoryEditMode: PropTypes.bool,
    articleData: PropTypes.object,
    header: PropTypes.string,
    content: PropTypes.string,
    resources: PropTypes.array,
    comments: PropTypes.array,
    modifiedByName: PropTypes.string,
    isVisible: PropTypes.bool,
    isCommentsAllowed: PropTypes.bool,
    onCategoryChange: PropTypes.func,
    onCategoryEditModeChange: PropTypes.func,
    onArticleEditModeChange: PropTypes.func
};

export default articleContext;
