export const ObjectBase = function () {
    var self = ObjectBase.Cast(this);
    var mHashCode = null;
    var mType = "ObjectBase";

    self._type = mType;

    self.getHashCode = () => {
        if (mHashCode === null) {
            mHashCode = (new Guid()).toString();
        }
        return mHashCode;
    }

    self.equals = (obj) => self === obj;

    self.getType = () => mType;

    self.setType = (type) => {
        self._type = mType = type;
        return self;
    }
};
ObjectBase.Cast = function (obj) { return obj; };
ObjectBase.CastArray = ObjectBase.Cast;
ObjectBase.IsUndefinedOrNull = function (obj) { return obj === undefined || obj === null; };

export const Exception = function (message, innerException) {
    var self = Exception.Cast(this);

    ObjectBase.prototype.constructor.call(self);
    self.setType("Exception");

    self.message = message || "";
    self.innerException = innerException || null;

    self.toString = () => {
        var result = self.message;
        if (ObjectBase.IsUndefinedOrNull(result)) {
            result = self._type;
        } else {
            result = self._type + ": " + result;
        }
        if (!ObjectBase.IsUndefinedOrNull(self.innerException)) {
            result = result + " ---> " + self.innerException.toString() + "\r\n";
        }
        return result;
    }

};
Exception.Cast = ObjectBase.Cast;
Exception.CastArray = ObjectBase.CastArray;

export const ArgumentException = function (message, innerException) {
    var self = ArgumentException.Cast(this);

    Exception.prototype.constructor.call(self, message, innerException);
    self.setType("ArgumentException");
};
ArgumentException.Cast = ObjectBase.Cast;
ArgumentException.CastArray = ObjectBase.CastArray;

export const ArgumentNullException = function (message, innerException) {
    var self = ArgumentNullException.Cast(this);

    ArgumentException.prototype.constructor.call(self, message, innerException);
    self.setType("ArgumentNullException");
};
ArgumentNullException.Cast = ObjectBase.Cast;
ArgumentNullException.CastArray = ObjectBase.CastArray;

export const ArgumentOutOfRangeException = function (message, innerException) {
    var self = ArgumentOutOfRangeException.Cast(this);

    ArgumentException.prototype.constructor.call(self, message, innerException);
    self.setType("ArgumentOutOfRangeException");
};
ArgumentOutOfRangeException.Cast = ObjectBase.Cast;
ArgumentOutOfRangeException.CastArray = ObjectBase.CastArray;

export const Guid = function () {
    var self = Guid.Cast(this);

    ObjectBase.prototype.constructor.call(self);
    self.setType("Guid");

    var mGuid = null;

    self.toString = () => mGuid;

    var S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);

    var CreateGuid = () => (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();

    mGuid = CreateGuid();
};
Guid.Cast = ObjectBase.Cast;
Guid.CastArray = ObjectBase.CastArray;
Object.preventExtensions(Guid);

export const StringBuilder = function () {
    var self = StringBuilder.Cast(this);

    ObjectBase.prototype.constructor.call(self);
    self.setType("StringBuilder");

    var mStrings = [];
    var mNewLine = "<br />";

    self.append = (value) => {
        var str = verify(value);
        if (str.length > 0) mStrings.push(str);
        return self;
    }

    self.appendLine = (value) => {
        if (ObjectBase.IsUndefinedOrNull(value)) {
            value = "";
        }
        var str = verify(value);
        mStrings.push(str.length > 0 ? str + mNewLine : mNewLine);
        return self;
    }

    self.clear = () => {
        mStrings = [];
        return self;
    }

    self.isEmpty = () => mStrings.length === 0;

    self.toString = () => mStrings.join("");

    self.length = () => {
        let len = 0;
        for (let [idx, str] of mStrings) {
            len = len + str.length;
        }
        return len;
    }

    self.getNewLineString = () => mNewLine;

    self.setNewLineString = (value) => {
        if (ObjectBase.IsUndefinedOrNull(value)) {
            throw new ArgumentNullException("Argument 'value' cannot be null.");
        }
        mNewLine = value;
        return self;
    };

    var verify = (str) => {
        if (!defined(str)) return "";
        if (getType(str) !== getType(String(""))) return String(str);
        return str;
    }

    var defined = function (value) {
        return value !== null && typeof value !== undefined;
    };

    var getType = (value) => {
        if (!defined(value.constructor)) throw new ArgumentException("Unexpected object type");
        var type = String(value.constructor).match(/function\s+(\w+)/);
        return defined(type) ? type[1] : undefined;
    }

};
StringBuilder.Cast = ObjectBase.Cast;
StringBuilder.CastArray = ObjectBase.CastArray;

export const EventArgs = function () {
    ObjectBase.prototype.constructor.call(this);
    this.setType("EventArgs");
};
EventArgs.Cast = ObjectBase.Cast;
EventArgs.CastArray = ObjectBase.CastArray;

export const GenericEvent = function () {
    var self = GenericEvent.Cast(this);

    ObjectBase.prototype.constructor.call(self);
    self.setType("GenericEvent");

    var mEventHandlers = [];

    self.addEventHandler = (eventHandler) => {
        if (eventHandler !== undefined && typeof eventHandler === "function" && eventHandler !== null) {
            mEventHandlers.push(eventHandler);
            self.count = mEventHandlers.length;
        } else {
            throw new ArgumentNullException("Invalid event handler.");
        }
        return self;
    };

    self.removeEventHandler = (eventHandler) => {
        if (eventHandler !== undefined && typeof eventHandler === "function" && eventHandler !== null) {
            while (mEventHandlers.length > 0) {
                var found = false;
                for (var i = 0; i < mEventHandlers.length; i++) {
                    var fn = mEventHandlers[i];
                    if (fn.guid !== undefined && eventHandler.guid !== undefined) {
                        if (fn.guid !== null && eventHandler.guid !== null && fn.guid === eventHandler.guid) {
                            mEventHandlers.splice(i, 1);
                            self.count = mEventHandlers.length;
                            found = true;
                            break;
                        }
                    } else if (fn.name === eventHandler.name && fn.length === eventHandler.length) {
                        mEventHandlers.splice(i, 1);
                        self.count = mEventHandlers.length;
                        found = true;
                        break;
                    }
                }
                if (found) break;
            }
        } else {
            throw new ArgumentNullException("Invalid event handler.");
        }
        return self;
    };

    self.raiseEvent = (sender, eventArgs) => {
        mEventHandlers.slice().forEach(function (item) {
            try {
                item(sender, eventArgs);
            }
            catch (e) {
                console.log(e);
            }
        });
        return self;
    };

    self.count = mEventHandlers.length;

};
GenericEvent.Cast = ObjectBase.Cast;
GenericEvent.CastArray = ObjectBase.CastArray;
