import React, { Component, Fragment } from "react";
import { Redirect } from 'react-router';

import notify from 'devextreme/ui/notify';

import { Urls } from "../../Urls";
import ArticleDataSource from "./ArticleDataSource";
import ArticleContext from "../Context/Article/ArticleContext";
import CategoryTreeView from "./CategoryTreeView";
import CategoryTreeList from "./CategoryTreeList";
import ArticleViewer from "./ArticleViewer";
import ArticleEditor from "./ArticleEditor";
import ArticleSearch from "./ArticleSearch";
import * as articleTypes from "./ArticleConsts";
import LoadingPopup from "../Popups/LoadingPopup";

import "./Article.css";

class Article extends Component {

    redirectToArticleId = -1;
    needArticleRedirect = false;
    needArticleLoad = true;
    connectionErrorDisplayed = false;

    constructor(props) {
        super(props);
        //console.log("Article.constructor");
        let newState = {
            isLoading: true,
            isError: false,
            isArticleEditMode: false,
            isCategoryEditMode: false,
            articleData: null,
            header: "",
            content: "",
            resources: [],
            comments: [],
            modifiedByName: "",
            isVisible: false,
            isCommentsAllowed: false,
            categories: [],
            needArticleRedirect: false,
            treeListFocusedRow: -1,
            showLoading: false
        }
        if (this.props.authContext.isAuthenticated && ArticleDataSource._categories !== null) {
            let id = parseInt(this.props.match.params.id === undefined ? "1" : (this.props.match.params.id < 1 ? "1" : this.props.match.params.id));
            // synchronize category tree selection with article in URL
            ArticleDataSource.selectCategory(id, true);
            newState.treeListFocusedRow = id;
        }
        this.state = newState;
    }

    componentDidMount() {
        //console.log("Article.componentDidMount");
        this.loadArticleFromUrl();
        ArticleDataSource.connectionChangedEvent.addEventHandler(this.dataSourceConnectionHandler);
        ArticleDataSource.articleCommentsChangedEvent.addEventHandler(this.articleCommentsChangedHandler);
        ArticleDataSource.articleResourcesChangedEvent.addEventHandler(this.articleResourcesChangedHandler);
        ArticleDataSource.categoryRemovedEvent.addEventHandler(this.categoryRemovedEventHandler);
        ArticleDataSource.categoryChangedEvent.addEventHandler(this.categoryChangedEventHandler);
    }

    componentWillUnmount() {
        //console.log("Article.componentWillUnmount");
        ArticleDataSource.connectionChangedEvent.removeEventHandler(this.dataSourceConnectionHandler);
        ArticleDataSource.articleCommentsChangedEvent.removeEventHandler(this.articleCommentsChangedHandler);
        ArticleDataSource.articleResourcesChangedEvent.removeEventHandler(this.articleResourcesChangedHandler);
        ArticleDataSource.categoryRemovedEvent.removeEventHandler(this.categoryRemovedEventHandler);
        ArticleDataSource.categoryChangedEvent.removeEventHandler(this.categoryChangedEventHandler);
    }

    dataSourceConnectionHandler = (s, e) => {
        //console.log("dataSourceConnectionHandler: " + e.connected);
        if (e.connected === false) {
            ArticleDataSource._articleStoreSubscribed = [this.state.articleData.Id];
            ArticleDataSource._articleStore = [];
            ArticleDataSource._articleStore[this.state.articleData.Id] = this.state.articleData;
            if (!this.connectionErrorDisplayed) {
                this.connectionErrorDisplayed = true;
                notify("Connection lost to the server.", "error", 2000);
            }
        } else {
            this.connectionErrorDisplayed = false;
            notify("Connection to the server re-established.", "success", 1000);
        }
    }

    articleCommentsChangedHandler = (s, e) => {
        //console.log("comment changed");
        this.setState({ articleData: e.article, comments: ArticleDataSource.deepCloneArray(e.article.Comments) });
    }

    articleResourcesChangedHandler = (s, e) => {
        this.setState({ articleData: e.article, resources: ArticleDataSource.deepCloneArray(e.article.Resources) });
    }

    categoryChangedEventHandler = (s, e) => {
        //console.log("Article.categoryChangedEventHandler, e.id: " + e.id);
        let id = e.id;
        let article = null;
        if (ArticleDataSource._articleStore.hasOwnProperty(id)) {
            article = ArticleDataSource._articleStore[id];
        }

        let newState = {
            categories: ArticleDataSource.cloneArray(ArticleDataSource._categories),
            visibleCategories: ArticleDataSource.cloneArray(ArticleDataSource.getVisibleCategories())
        };

        //console.log("article: " + article);
        if (article !== null) {

            //console.log("this.state.articleData: " + this.state.articleData);
            //if (this.state.articleData !== null) console.log("this.state.articleData.Id: " + this.state.articleData.Id);

            if (this.state.articleData === null || this.state.articleData.Id === article.Id) {
                newState.articleData = article;
                newState.header = article.Header;
                newState.content = article.Content;
                newState.modifiedByName = article.ModifiedByName;
                newState.isVisible = article.IsVisible;
                newState.isCommentsAllowed = article.IsCommentsAllowed;
                newState.comments = ArticleDataSource.deepCloneArray(article.Comments);
                newState.resources = ArticleDataSource.deepCloneArray(article.Resources);
            }
        }

        this.setState(newState);
    }

    categoryRemovedEventHandler = (s, e) => {
        //console.log("Article.categoryRemovedEventHandler");
        let data = this.state.articleData;
        if (data !== null && data.Id === e.id) {
            // current article removed
            //console.log("Article, current article removed");
            this.needArticleRedirect = true;
            this.redirectToArticleId = 1;
            this.needArticleLoad = true;

            ArticleDataSource.selectCategory(1, true);

            this.setState((prevState, props) => ({
                needArticleRedirect: !prevState.needArticleRedirect,
                categories: ArticleDataSource.cloneArray(ArticleDataSource._categories),
                visibleCategories: ArticleDataSource.cloneArray(ArticleDataSource.getVisibleCategories()),
                treeListFocusedRow: 1
            }));
        } else {
            this.setState({
                categories: ArticleDataSource.cloneArray(ArticleDataSource._categories),
                visibleCategories: ArticleDataSource.cloneArray(ArticleDataSource.getVisibleCategories())
            });
        }
    }

    loadArticleFromUrl = () => {
        if (this.props.authContext.isAuthenticated) {
            let id = parseInt(this.props.match.params.id === undefined ? "1" : (this.props.match.params.id < 1 ? "1" : this.props.match.params.id));

            //console.log("Article.loadArticleFromUrl, article id: " + id);
            let reloadCategories = ArticleDataSource._categories.length === 0 || this.state.categories.length !== ArticleDataSource._categories;

            setTimeout(() => {
                this.setState({ showLoading: true });
            }, 1);

            ArticleDataSource.getCategories()
                .then((categories) => {

                    //console.log("Article.loadArticleFromUrl, categories loaded.");

                    // load the article
                    ArticleDataSource.getArticle(id)
                        .then((data) => {
                            //console.log("Article.loadArticleFromUrl, article loaded.");
                            ArticleDataSource.selectCategory(id, true);
                            let newState = {
                                isLoading: false,
                                articleData: data,
                                header: data.Header,
                                content: data.Content,
                                resources: ArticleDataSource.deepCloneArray(data.Resources),
                                comments: ArticleDataSource.deepCloneArray(data.Comments),
                                modifiedByName: data.ModifiedByName,
                                isVisible: data.IsVisible,
                                isCommentsAllowed: data.IsCommentsAllowed,
                                treeListFocusedRow: id,
                                showLoading: false
                            };
                            if (reloadCategories) {
                                newState.categories = ArticleDataSource.cloneArray(ArticleDataSource._categories);
                                newState.visibleCategories = ArticleDataSource.cloneArray(ArticleDataSource.getVisibleCategories());
                            }
                            this.setState(newState);
                        })
                        .catch((error) => {
                            this.setState({ isLoading: false, isError: true, showLoading: false });
                            //console.log(error);
                        });

                })
                .catch((reason) => {
                    this.setState({ isLoading: false, isError: true, showLoading: false });
                });
        }
    }

    onCategorySelectionChanged = (category) => {
        //console.log("Article.onCategorySelectionChanged, id: " + category.Id + ", IsSelected: " + category.IsSelected.toString());

        ArticleDataSource.selectCategory(category.Id, category.IsSelected);

        if (category.IsSelected && (category.ArticleType === articleTypes.LEAF || category.ArticleType === articleTypes.ROOT_LEAF)) {
            this.needArticleRedirect = true;
            this.redirectToArticleId = category.Id;
            this.needArticleLoad = true;

            this.setState((prevState, props) => ({
                needArticleRedirect: !prevState.needArticleRedirect,
                treeListFocusedRow: category.Id
            }));
        } else {
            this.setState({ treeListFocusedRow: category.Id });
        }
    }

    onCategoryEditModeChange = (isEdit) => {
        this.setState({ isCategoryEditMode: isEdit });
    }

    onArticleEditModeChange = (isEdit) => {
        this.setState({ isArticleEditMode: isEdit });
    }

    searchNavigateToArticleCallback = (id) => {
        ArticleDataSource.selectCategory(id, true);
        this.needArticleRedirect = true;
        this.redirectToArticleId = id;
        this.needArticleLoad = true;

        this.setState((prevState, props) => ({
            needArticleRedirect: !prevState.needArticleRedirect,
            treeListFocusedRow: id
        }));
    }

    renderArticleViewer = (articleContext) => {
        return (
            <Fragment>
                <ArticleSearch navigateToArticleCallback={this.searchNavigateToArticleCallback} />
                <ArticleViewer articleContext={articleContext} />
            </Fragment>
        );
    }

    renderContent = () => {
        if (this.state.isLoading) {
            return (
                <div className="centeredContextFlex container">
                    <div>
                        <p className="pCenter">Loading article, please wait ...</p>
                    </div>
                </div>
            );
        }

        if (this.state.isError) {
            return (
                <div className="centeredContextFlex container">
                    <div>
                        <p className="pCenter">We're sorry. The article, what you are looking for, does not exist or unable to load it.</p>
                    </div>
                </div>
            );
        }

        let needArtRedirect = this.needArticleRedirect; // if I don't render the controls, constructort will re-run
        if (this.needArticleRedirect) {
            //console.log("Article.needArticleRedirect");
            this.needArticleRedirect = false;
        } else if (this.needArticleLoad) {
            this.needArticleLoad = false;
            this.redirectToArticleId = -1;
            this.loadArticleFromUrl();
        }

        return (
            <ArticleContext.Consumer>
                {
                    articleContext =>
                        <div className="article-flex-container">
                            <div className="article-categories">
                                {this.state.isCategoryEditMode ? <CategoryTreeList articleContext={articleContext} /> : <CategoryTreeView articleContext={articleContext} isArticleEditMode={this.state.isArticleEditMode} />}
                            </div>
                            <div className="article-content">
                                {this.state.isArticleEditMode ? <ArticleEditor articleContext={articleContext} /> : this.renderArticleViewer(articleContext)}
                            </div>
                            {needArtRedirect ? <Redirect push to={Urls.ARTICLE + this.redirectToArticleId.toString()} /> : null}
                        </div>
                }
            </ArticleContext.Consumer>
        );
    }

    render() {
        return (
            <Fragment>
                {this.props.authContext.isAuthenticated ?
                    <ArticleContext.Provider value={{
                        isArticleEditMode: this.state.isArticleEditMode,
                        isCategoryEditMode: this.state.isCategoryEditMode,

                        articleData: this.state.articleData,
                        header: this.state.header,
                        content: this.state.content,
                        resources: this.state.resources,
                        comments: this.state.comments,
                        modifiedByName: this.state.modifiedByName,
                        isVisible: this.state.isVisible,
                        isCommentsAllowed: this.state.isCommentsAllowed,

                        categories: this.state.categories,
                        visibleCategories: this.state.visibleCategories,
                        treeListFocusedRow: this.state.treeListFocusedRow,

                        onCategoryChange: this.onCategorySelectionChanged,
                        onCategoryEditModeChange: this.onCategoryEditModeChange,
                        onArticleEditModeChange: this.onArticleEditModeChange
                    }}>
                        <Fragment>
                            {this.renderContent()}
                        </Fragment>
                    </ArticleContext.Provider>
                    :
                    <Redirect push to={Urls.LANDING} />}
                <LoadingPopup isVisible={this.state.showLoading} />
            </Fragment>
        );
    }

}

export default Article;
