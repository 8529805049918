import React, { Component } from "react";

import { Popup } from 'devextreme-react/popup';
import { Button } from 'devextreme-react';

import "./ConfirmPopup.css";

export default class ConfirmPopup extends Component {

    render() {
        return (
            <Popup
                visible={true}
                dragEnabled={true}
                closeOnOutsideClick={false}
                showTitle={true}
                title="Confirmation"
                width={300}
                height={180}>
                <div className="centeredContextFlex popupConfirmFlexDirCol">
                    <div>
                        <p className="pCenter">Are you sure?</p>
                    </div>
                    <div className="centeredContextFlex popupConfirmFlexDirRow">
                        <Button
                            width="7rem"
                            text="Yes"
                            hint="Yes"
                            type="default"
                            stylingMode="contained"
                            onClick={this.props.okCallback}
                        />
                        <div className="popupConfirmSpace"></div>
                        <Button
                            width="7rem"
                            text="No"
                            hint="No"
                            type="default"
                            stylingMode="contained"
                            onClick={this.props.cancelCallback}
                        />
                    </div>
                </div>
            </Popup>
        );
    }

}
