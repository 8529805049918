import React, { Component, Fragment } from "react";
import { Redirect } from 'react-router';
import axios from "axios";

import { Button } from 'devextreme-react';
import { TextBox } from 'devextreme-react/text-box';
import Validator, { RequiredRule, CustomRule } from 'devextreme-react/validator';
import { Popup } from 'devextreme-react/popup';
import ValidationGroup from 'devextreme-react/validation-group';

import PageTitle from "../Auxilary/PageTitle";
import { Urls } from "../../Urls";
import LoadingPopup from "../Popups/LoadingPopup";

import "./ResetPassword.css";

class ResetPassword extends Component {

    password1BoxRef = React.createRef();
    password2BoxRef = React.createRef();

    state = {
        loginName: "",
        password1: "",
        password2: "",
        code: "",
        isFailedPopupVisible: false,
        isSuccessPopupVisible: false,
        showPasswordInfo: false,
        redirectToSignInPage: false,
        showPasswordReqInfo: false,
        showLoading: false
    }

    get password1Box() {
        return this.password1BoxRef.current.instance;
    }

    get password2Box() {
        return this.password2BoxRef.current.instance;
    }

    componentDidMount() {
        PageTitle.setTitle("Reset your password | " + PageTitle.defaultText);
        var emailElem = document.getElementById("Email");
        if (emailElem !== undefined) {
            this.setState({ loginName: emailElem.value });
            emailElem.remove();
        }
        var codeElem = document.getElementById("Code");
        if (codeElem !== undefined) {
            this.setState({ code: codeElem.value });
            codeElem.remove();
        }
    }

    password1ChangeHandler = (e) => {
        this.setState({ password1: e.value });
    }

    password2ChangeHandler = (e) => {
        this.setState({ password2: e.value });
    }

    passwordRequirementsCallback = (e) => {
        var result = this.state.password1.length >= 8;
        if (result) result = this.state.password1.search("[0-9]") !== -1;
        if (result) result = this.state.password1.search("[a-z]") !== -1;
        if (result) result = this.state.password1.search("[A-Z]") !== -1;
        if (!result) this.setState({ showPasswordReqInfo: true });
        return result;
    }

    passwordComparatorCallback = (e) => {
        return this.state.password1 === this.state.password2;
    }

    btnResetClick = (e) => {
        if (!e.validationGroup.validate().isValid) {
            return;
        }

        this.setState({ showLoading: true });
        axios.post(Urls.RESET_PASSWORD_PERFORM,
            {
                Email: this.state.loginName,
                Password: this.state.password1,
                ConfirmPassword: this.state.password2,
                Code: this.state.code
            })
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.Succeeded) {
                        e.validationGroup.reset();
                        this.setState({ showPasswordInfo: false, isSuccessPopupVisible: true, showLoading: false });
                    } else {
                        this.setState({ isFailedPopupVisible: true, showLoading: false });
                    }
                } else {
                    this.setState({ isFailedPopupVisible: true, showLoading: false });
                }
            }, (error) => {
                    this.setState({ isFailedPopupVisible: true, showLoading: false });
            })

    }

    getPasswordReqInfo = () => {
        return (
            <div>
                <div className="centeredContent">
                    <div className="divPassResetPassPanel">
                        <p>Password requirements:</p>
                        <ul>
                            <li>Minimum 8 characters</li>
                            <li>Alphabets: [a-z]</li>
                            <li>Alphabets: [A-Z]</li>
                            <li>Numbers: [0-9]</li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    hideFailedPopup = () => {
        this.setState({ isFailedPopupVisible: false });
    }

    hideSuccessPopup = () => {
        this.setState({ isSuccessPopupVisible: false, redirectToSignInPage: true });
    }

    renderResetForm = () => {
        return (
            <div className="scrollY height100 pt-4 pb-4">
                <div className="centeredContextFlex flexDirRow">
                    <ValidationGroup>
                        <p className="pHeader resetPassAfterMargin pLeft">Reset password</p>
                        <div className="resetPassAfterMargin pLeft">
                            <p>New password:</p>
                            <TextBox
                                id="pw1"
                                onValueChanged={this.password1ChangeHandler}
                                value={this.state.password1}
                                hint="Password"
                                showClearButton={true}
                                mode="password"
                                width="15rem"
                                ref={this.password1BoxRef}>
                                <Validator>
                                    <RequiredRule message={'Password is required'} />
                                    <CustomRule
                                        message="Password does not meet the requirements."
                                        validationCallback={this.passwordRequirementsCallback}
                                        reevaluate={true}
                                    />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className="resetPassAfterMargin pLeft">
                            <p>Confirm password:</p>
                            <TextBox
                                id="pw2"
                                onValueChanged={this.password2ChangeHandler}
                                value={this.state.password2}
                                hint="Password"
                                showClearButton={true}
                                mode="password"
                                width="15rem"
                                ref={this.password2BoxRef}>
                                <Validator>
                                    <RequiredRule message={'Password is required'} />
                                    <CustomRule
                                        message="Passwords are not the same"
                                        validationCallback={this.passwordComparatorCallback}
                                        reevaluate={true}
                                    />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className="resetPassAfterMargin">
                            <Button
                                width="15rem"
                                text="Change Password"
                                hint="Change password"
                                type="default"
                                stylingMode="contained"
                                onClick={(e) => this.btnResetClick(e)}
                            />
                        </div>
                    </ValidationGroup>
                    {this.state.showPasswordReqInfo ? this.getPasswordReqInfo() : null}
                </div>
            </div>
        );
    }

    render() {
        return (
            <Fragment>
                {this.renderResetForm()}
                <Popup
                    visible={this.state.isFailedPopupVisible}
                    onHiding={this.hideFailedPopup}
                    dragEnabled={true}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    title="Forgot Password"
                    width={300}
                    height={200}>
                    <div className="profPopupCenter">
                        <div className="centeredContent">
                            <p className="pCenter">
                                Failed to change your password.
                            </p>
                            <Button
                                width="14.5rem"
                                text="OK"
                                type="default"
                                stylingMode="contained"
                                onClick={() => this.hideFailedPopup()}
                            />
                        </div>
                    </div>
                </Popup>
                <Popup
                    visible={this.state.isSuccessPopupVisible}
                    onHiding={this.hideSuccessPopup}
                    dragEnabled={true}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    title="Forgot Password"
                    width={300}
                    height={200}>
                    <div className="profPopupCenter">
                        <div className="centeredContent">
                            <p className="pCenter">
                                Your password changed.
                            </p>
                            <Button
                                width="14.5rem"
                                text="OK"
                                type="default"
                                stylingMode="contained"
                                onClick={() => this.hideSuccessPopup()}
                            />
                        </div>
                    </div>
                </Popup>
                {this.state.redirectToSignInPage ? <Redirect push to={Urls.LOGIN} /> : null}
                <LoadingPopup isVisible={this.state.showLoading} />
            </Fragment>
        );
    }

}

export default ResetPassword;
