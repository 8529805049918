import React, { Component, Fragment } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';

import AuthContext from "./Context/Auth/AuthContext";
import { Urls } from "../Urls";
import ArticleDataSource from "./Article/ArticleDataSource";

import "./Layout.css";

export class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            returnUrl: window.location.pathname === '/' ? Urls.ARTICLE + '1' : window.location.pathname,
            userData: {
                firstName: "",
                lastName: "",
                email: "",
                userType: 2,
                securityRights: [] // (LandingPage, Login)
            }
        };
    }

    changeAuthContextData = (jsonData) => {
        //console.log("Layout.changeAuthContextData: " + JSON.stringify(jsonData));
        this.setState(jsonData);
        ArticleDataSource._authUserData = this.state.userData;
    }

    render() {
        return (
            <Fragment>
                <AuthContext.Provider value={{ isAuthenticated: this.state.isAuthenticated, returnUrl: this.state.returnUrl, userData: this.state.userData, onChangeContextData: this.changeAuthContextData }}>
                    <NavMenu />
                    <Container className="divContainer maxWidthContainer">
                        {this.props.children}
                    </Container>
                </AuthContext.Provider>
            </Fragment>
        );
    }
}
