import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

import AccountMenu from './AccountMenu.js';

import './NavMenu.css';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        // d-sm-inline-flex flex-sm-row-reverse
        return (
            <div className="divNavigation">
                <Navbar className="navbar-expand-sm navbar-toggleable-sm nav-background navbar-dark box-shadow" light={false}>
                    <Container className="maxWidthContainer">
                        <NavbarBrand tag={Link} to="/">Sesame Docs</NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2 navbar-toggler-right" />
                        <Collapse isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav mr-auto">
                                <NavItem>
                                    <NavLink tag={Link} className="navbar-dark" to="/Article/1">Articles</NavLink>
                                </NavItem>
                                <li>
                                    <a target="_blank" className="navbar-dark nav-link" href="http://mantis.sesame-systems.com/sesame/my_view_page.php" rel="noopener noreferer">Support</a>
                                </li>
                                <NavItem>
                                    <NavLink tag={Link} className="navbar-dark" to="/Contact">Contact</NavLink>
                                </NavItem>
                            </ul>
                            <AccountMenu />
                        </Collapse>
                    </Container>
                </Navbar>
            </div>
        );
    }
}
