import React, { Component, Fragment } from "react";

import notify from 'devextreme/ui/notify';

import { UserSecurityCheck } from "../Context/Auth/AuthContext";
import ConfirmPopup from "../Popups/ConfirmPopup";
import ArticleDataSource from "./ArticleDataSource";

import "./ArticleComment.css";
import delImg from "../../assets/delete.png";

class ArticleComment extends Component {

    state = {
        confirmPopupVisible: false,
        showLoading: false
    }

    btnRemoveCommentClickHandler = (e) => {
        this.setState({ confirmPopupVisible: true });
    }

    onCommentRemoveConfirmed = () => {
        this.setState({ confirmPopupVisible: false, showLoading: true });
        ArticleDataSource.removeArticleComment(this.props.ArticleId, this.props.Id)
            .catch((error) => {
                this.setState({ confirmPopupVisible: false, showLoading: false });
                notify("Unable to remove comment.", "error", 2000);
            });
    }

    onConfirmPopupCancel = () => {
        this.setState({ confirmPopupVisible: false });
    }

    render() {

        let authContext = this.props.AuthContext;
        let allowRemove = UserSecurityCheck.isAdminArticle(authContext.userData) || authContext.userData.userId === this.props.OwnerUserId;

        return (
            <Fragment>
                <div className="articleComment bottomShadow articleBackground mt-3">
                    <div className="articleCommentFlexContainer articleCommandBottomBorder articleCommentPad">
                        <div className="articleCommentUserName">
                            <p className="articleCommentP articleCommentP_bold">{this.props.Name}</p>
                        </div>
                        <div>
                            <p className="articleCommentP articleCommentP_bold">{this.props.CreatedTime}</p>
                        </div>
                        {allowRemove ?
                            <div className="centeredContextFlex articleCommentButtonContainer pRight ml-2">
                                <img src={delImg} title="Remove this comment" alt="Remove this comment" onClick={this.btnRemoveCommentClickHandler} />
                            </div> : null}
                    </div>
                    <div className="articleCommentPad articleCommentMessageBG">
                        <p className="articleCommentMessageP mt-3 mb-3">{this.props.Message}</p>
                    </div>
                </div>
                {this.state.confirmPopupVisible ? <ConfirmPopup okCallback={this.onCommentRemoveConfirmed} cancelCallback={this.onConfirmPopupCancel} /> : null}
            </Fragment>
        );
    }

}

export default ArticleComment;
