import React, { Component, Fragment } from "react";
import { Redirect } from 'react-router';
import axios from "axios";

import { Button } from 'devextreme-react';
import { TextBox } from 'devextreme-react/text-box';
import Validator, { RequiredRule, CustomRule } from 'devextreme-react/validator';
import { Popup } from 'devextreme-react/popup';
import ValidationGroup from 'devextreme-react/validation-group';
import notify from 'devextreme/ui/notify';

import PageTitle from "../Auxilary/PageTitle";
import AuthContext from "../Context/Auth/AuthContext";
import { Urls } from "../../Urls";
import LoadingPopup from "../Popups/LoadingPopup";

import "./UserProfile.css";

class UserProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstName: props.authContext.userData.firstName,
            lastName: props.authContext.userData.lastName,
            currentPassword: "",
            newPassword: "",
            isFailedPopupVisible: false,
            showPasswordProfInfo: false,
            showLoading: false
        }
    }

    componentDidMount() {
        PageTitle.setTitle("My Profile | " + PageTitle.defaultText);
    }

    firstNameChangeHandler = (e) => {
        this.setState({ firstName: e.value });
    }

    lastNameChangeHandler = (e) => {
        this.setState({ lastName: e.value });
    }

    currentPasswordChangeHandler = (e) => {
        this.setState({ currentPassword: e.value });
    }

    newPasswordChangeHandler = (e) => {
        this.setState({ newPassword: e.value });
    }

    currentPasswordRequirementsCallback = (e) => {
        return this.state.newPassword.length === 0 || (this.state.newPassword.length > 0 && this.state.currentPassword.length > 0);
    }

    newPasswordRequirementsCallback = (e) => {
        var result = true;
        if (this.state.newPassword.length > 0) {
            if (result) result = this.state.newPassword.search("[0-9]") !== -1;
            if (result) result = this.state.newPassword.search("[a-z]") !== -1;
            if (result) result = this.state.newPassword.search("[A-Z]") !== -1;
            if (!result) this.setState({ showPasswordProfInfo: true });
        }
        return result;
    }

    getPasswordProfInfo = () => {
        return (
            <div className="divPassProfCenterPanel">
                <div className="centeredContent">
                    <div className="divPassProfPanel">
                        <p>Password requirements:</p>
                        <ul>
                            <li>Minimum 8 characters</li>
                            <li>Alphabets: [a-z]</li>
                            <li>Alphabets: [A-Z]</li>
                            <li>Numbers: [0-9]</li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    hideFailedPopup = () => {
        this.setState({ isFailedPopupVisible: false });
    }

    btnSaveClick = (e, authContext) => {
        //console.log("SAVE");
        if (!e.validationGroup.validate().isValid) {
            return;
        }

        this.setState({ showLoading: true });
        var firstName = this.state.firstName; //this.firstNameBox.element().childNodes[0].childNodes[0].childNodes[0].value;
        var lastName = this.state.lastName; //this.lastNameBox.element().childNodes[0].childNodes[0].childNodes[0].value;
        axios.post(Urls.USER_PROFILE_UPDATE, { Username: authContext.userData.email, FirstName: firstName, Lastname: lastName, CurrentPassword: this.state.currentPassword, NewPassword: this.state.newPassword })
            .then((response) => {
                //console.log(response);
                if (response.status === 200) {
                    if (response.data.Succeeded) {
                        authContext.onChangeContextData({
                            userData: {
                                userId: authContext.userData.userId,
                                firstName: firstName,
                                lastName: lastName,
                                email: authContext.userData.email,
                                userType: authContext.userData.userType,
                                securityRights: [...authContext.userData.securityRights],
                                isExternalUser: authContext.userData.IsExternalUser
                            }
                        });
                        this.setState({ showLoading: false });
                        notify("Your profile successfully modified.", "success", 1000);
                    } else {
                        this.setState({ isFailedPopupVisible: true, showLoading: false });
                    }
                } else {
                    this.setState({ isFailedPopupVisible: true, showLoading: false });
                }
            },
                (error) => {
                    this.setState({ isFailedPopupVisible: true, showLoading: false });
                })

    }

    renderProfileForm = (authContext) => {
        return (
            <div className="scrollY height100 pt-4 pb-4">
                <div className="centeredContextFlex">
                    <ValidationGroup className="bottomShadow">
                        <p className="pHeader regAfterMargin pLeft">My Profile Data</p>
                        <div className="regAfterMargin pLeft">
                            <p>User name / email address:</p>
                            <TextBox
                                id="usrEml"
                                readOnly={true}
                                value={authContext.userData.email}
                                showClearButton={false}
                                disabled={authContext.userData.isExternalUser}
                                width="15rem">
                            </TextBox>
                        </div>
                        <div className="profAfterMargin pLeft">
                            <p>First name:</p>
                            <TextBox
                                id="fstNm"
                                onValueChanged={this.firstNameChangeHandler}
                                value={this.state.firstName}
                                disabled={authContext.userData.userType < 2}
                                hint="First name"
                                showClearButton={true}
                                width="15rem">
                                <Validator>
                                    <RequiredRule message={'First name is required'} />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className="profAfterMargin pLeft">
                            <p>Last name:</p>
                            <TextBox
                                id="lnNm"
                                onValueChanged={this.lastNameChangeHandler}
                                value={this.state.lastName}
                                disabled={authContext.userData.userType < 2}
                                hint="Last name"
                                showClearButton={true}
                                width="15rem">
                                <Validator>
                                    <RequiredRule message={'Last name is required'} />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className="profAfterMargin pLeft">
                            <p>Current password:</p>
                            <TextBox
                                id="cPw"
                                onValueChanged={this.currentPasswordChangeHandler}
                                value={this.state.currentPassword}
                                hint="Your current password"
                                showClearButton={true}
                                mode="password"
                                width="15rem">
                                <Validator>
                                    <CustomRule
                                        message="Password is required."
                                        validationCallback={this.currentPasswordRequirementsCallback}
                                        reevaluate={true}
                                    />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className="profAfterMargin pLeft">
                            <p>New password:</p>
                            <TextBox
                                id="nPw"
                                onValueChanged={this.newPasswordChangeHandler}
                                value={this.state.newPassword}
                                hint="Your new password"
                                showClearButton={true}
                                mode="password"
                                width="15rem">
                                <Validator>
                                    <CustomRule
                                        message="Password does not meet the requirements."
                                        validationCallback={this.newPasswordRequirementsCallback}
                                        reevaluate={true}
                                    />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className="profAfterMargin">
                            <Button
                                width="15rem"
                                text="Save"
                                hint="Save changes"
                                type="default"
                                stylingMode="contained"
                                onClick={(e) => this.btnSaveClick(e, authContext)}
                            />
                        </div>
                    </ValidationGroup>
                </div>
            </div>
        );
    }

    render() {
        return (
            <AuthContext.Consumer>
                {
                    authContext =>
                        <Fragment>
                            {authContext.isAuthenticated ?
                                this.renderProfileForm(authContext) : <Redirect push to={Urls.LANDING} />}
                            <Popup
                                visible={this.state.isFailedPopupVisible}
                                onHiding={this.hideFailedPopup}
                                dragEnabled={true}
                                closeOnOutsideClick={true}
                                showTitle={true}
                                title="Profile"
                                width={300}
                                height={200}>
                                <div className="profPopupCenter">
                                    <div className="centeredContent">
                                        <p className="pCenter">
                                            Unable to modify your details.<br />Please check your current password.
                                        </p>
                                        <Button
                                            width="14.5rem"
                                            text="OK"
                                            type="default"
                                            stylingMode="contained"
                                            onClick={() => this.hideFailedPopup()}
                                        />
                                    </div>
                                </div>
                            </Popup>
                            {this.state.showPasswordProfInfo ? this.getPasswordProfInfo() : null}
                            <LoadingPopup isVisible={this.state.showLoading} />
                        </Fragment>
                }
            </AuthContext.Consumer>
        );
    }

}

export default UserProfile;
