import React, { Component, Fragment } from "react";

import { LoadPanel } from 'devextreme-react/load-panel';

export default class LoadingPopup extends Component {

    timeoutId = -1;

    componentWillUnmount() {
        this.clearMyTimeout();
    }

    clearMyTimeout = () => {
        if (this.timeoutId !== -1) {
            clearTimeout(this.timeoutId);
            this.timeoutId = -1;
        }
    }

    render() {
        //console.log("LoadingPopup.render, isVisible: " + this.props.isVisible);

        if (this.timeoutId < 0 && this.props.isVisible) {
            this.timeoutId = setTimeout(() => {
                this.setState({ isVisible: true });
            }, 1000);
            return <Fragment></Fragment>;
        }

        this.clearMyTimeout();

        return (
            <LoadPanel
                shadingColor="rgba(0,107,185,0.4)"
                position={{ of: 'body' }}
                visible={this.props.isVisible}
                showIndicator={true}
                shading={true}
                showPane={true}
                closeOnOutsideClick={false} />
        );
    }

}
