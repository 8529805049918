import React, { Component, Fragment } from "react";
import { Redirect } from 'react-router';

import axios from "axios";

import DataGrid, { Column, Pager, Paging, Sorting } from 'devextreme-react/data-grid';
import notify from 'devextreme/ui/notify';
import { Button } from 'devextreme-react';
import CheckBox from 'devextreme-react/check-box';

import PageTitle from "../Auxilary/PageTitle";
import AuthContext from "../Context/Auth/AuthContext";
import { Urls } from "../../Urls";
import LoadingPopup from "../Popups/LoadingPopup";

import "./AdminUsers.css";

export default class AdminUsers extends Component {

    state = {
        isInitialized: false,
        users: [],
        selectedUser: null,
        IsEditorPlaceVisible: false,
        IsAccountDisabled: false,
        IsRightAdmin: false,
        IsRightAdminArticle: false,
        IsRightKnowledgeProfessional: false,
        IsButtonsDisabled: false,
        showLoading: false
    }

    componentDidMount() {
        PageTitle.setTitle("User Administration | " + PageTitle.defaultText);
    }

    loadUserList = () => {
        this.setState({ showLoading: true, isInitialized: true, IsButtonsDisabled: true, IsEditorPlaceVisible: false });
        axios.get(Urls.ADMIN_USERS_GETUSERS)
            .then((response) => {
                if (response.status === 200 && response.data.Succeeded) {
                    this.setState({ showLoading: false, users: response.data.Users, selectedUser: null, IsButtonsDisabled: false });
                } else {
                    this.setState({ showLoading: false, users: [], selectedUser: null, IsButtonsDisabled: false });
                    this.displayLoadUsersFailedNotify();
                }
            })
            .catch((error) => {
                this.setState({ showLoading: false, users: [], selectedUser: null, IsButtonsDisabled: false });
                this.displayLoadUsersFailedNotify();
            });
    }

    displayLoadUsersFailedNotify = () => {
        notify("Unable to load user list.", "error", 2000);
    }

    onSelectionChanged = ({ selectedRowsData }) => {
        const data = selectedRowsData[0];
        this.setState({
            selectedUser: data,
            IsEditorPlaceVisible: true,
            IsAccountDisabled: data.IsAccountDisabled,
            IsRightAdmin: data.IsRightAdmin,
            IsRightAdminArticle: data.IsRightAdminArticle,
            IsRightKnowledgeProfessional: data.IsRightKnowledgeProfessional
        });
    }

    cbAccountDisabledValueChanged = (e) => {
        this.setState({ IsAccountDisabled: e.value });
    }

    cbRightAdminValueChanged = (e) => {
        this.setState({ IsRightAdmin: e.value });
    }

    cbRightAdminArticleValueChanged = (e) => {
        this.setState({ IsRightAdminArticle: e.value });
    }

    cbRightKnowledgeProfessionalValueChanged = (e) => {
        this.setState({ IsRightKnowledgeProfessional: e.value });
    }

    btnUpdateClickHandler = (e) => {
        this.setState({ showLoading: true, IsButtonsDisabled: true });
        axios.post(Urls.ADMIN_USERS_UPDATE_SETTINGS, {
            Id: this.state.selectedUser.Id,
            IsAccountDisabled: this.state.IsAccountDisabled,
            IsRightAdmin: this.state.IsRightAdmin,
            IsRightAdminArticle: this.state.IsRightAdminArticle,
            IsRightKnowledgeProfessional: this.state.IsRightKnowledgeProfessional
        })
            .then((response) => {
                var newState = { showLoading: false, IsButtonsDisabled: false };
                if (response.status === 200 && response.data.Succeeded) {
                    let selectedUser = this.state.selectedUser;
                    selectedUser.IsAccountDisabled = this.state.IsAccountDisabled;
                    selectedUser.IsRightAdmin = this.state.IsRightAdmin;
                    selectedUser.IsRightAdminArticle = this.state.IsRightAdminArticle;
                    selectedUser.IsRightKnowledgeProfessional = this.state.IsRightKnowledgeProfessional;
                    newState.users = [...this.state.users];
                    notify("User settings successfully updated.", "success", 1000);
                } else {
                    this.displayUpdateUserSettingFailedNotify();
                }
                this.setState(newState);
            })
            .catch((error) => {
                this.setState({ showLoading: false, IsButtonsDisabled: false });
                this.displayUpdateUserSettingFailedNotify();
            });
    }

    displayUpdateUserSettingFailedNotify = () => {
        notify("Unable to update user settings.", "error", 2000);
    }

    btnRefreshClickHandler = (e) => {
        this.loadUserList();
    }

    btnReSendClickHandler = (e) => {
        this.setState({ showLoading: true, IsButtonsDisabled: true });
        axios.post(Urls.ADMIN_USERS_RESEND_CONFIRM_EMAIL, { Id: this.state.selectedUser.Id })
            .then((response) => {
                if (response.status === 200 && response.data.Succeeded) {
                    this.setState({ showLoading: false, IsButtonsDisabled: false });
                    notify("Confirmation email enqueued into the outgoing email queue.", "success", 1000);
                } else {
                    this.setState({ showLoading: false, IsButtonsDisabled: false });
                    this.displayResendFailedNotify();
                }
            })
            .catch((error) => {
                this.setState({ showLoading: false, IsButtonsDisabled: false });
                this.displayResendFailedNotify();
            });
    }

    displayResendFailedNotify = () => {
        notify("Unable to resend the email.", "error", 2000);
    }

    renderAdminSection = () => {
        if (!this.state.isInitialized) {
            setTimeout(() => {
                this.loadUserList();
            }, 1);
        }
        return (
            <Fragment>
                <div className="centeredContextFlex pt-4">
                    <div className="gridContainer gridContainerFlex">
                        <div className="maxWidthContainer adminAfterMargin">
                            <p className="pHeader pLeft">Administrate users</p>
                        </div>
                        <div className="maxWidthContainer gridContainerOverflow mb-4 gridContainerShadow">
                            <div className="adminAfterMargin">
                                <DataGrid
                                    dataSource={this.state.users}
                                    selection={{ mode: 'single' }}
                                    showBorders={true}
                                    showRowLines={true}
                                    hoverStateEnabled={true}
                                    allowColumnResizing={true}
                                    columnResizingMode="nextColumn"
                                    columnAutoWidth={true}
                                    keyExpr="Id"
                                    onSelectionChanged={this.onSelectionChanged}
                                >
                                    <Sorting mode="multiple" key="Fullname" />
                                    <Paging defaultPageSize={10} />
                                    <Pager
                                        showPageSizeSelector={true}
                                        allowedPageSizes={[5, 10, 20]}
                                        showInfo={true} />

                                    <Column dataField="Fullname" caption="Name" />
                                    <Column dataField="Email" caption="Email" />
                                    <Column dataField="IsRightAdmin" caption="Administrator" />
                                    <Column dataField="IsRightAdminArticle" caption="Articles" />
                                    <Column dataField="IsRightKnowledgeProfessional" caption="Professional" />
                                    <Column dataField="IsAccountDisabled" caption="Disabled" />
                                </DataGrid>
                            </div>
                            <div className="adminAfterMargin">
                                <Button
                                    className="ml-2"
                                    width="15rem"
                                    text="Refresh"
                                    hint="Refresh the list"
                                    type="default"
                                    stylingMode="contained"
                                    disabled={this.state.IsButtonsDisabled}
                                    onClick={this.btnRefreshClickHandler}
                                />
                            </div>
                        </div>
                        {this.state.IsEditorPlaceVisible ?
                            <div className="maxWidthContainer gridContainerShadow mb-4">
                                <div className="maxWidthContainer ml-2 mr-2">
                                    <div className="adminAfterMargin pLeft">
                                        <p>{this.state.selectedUser.Fullname + " (" + this.state.selectedUser.Email + ")"}</p>
                                    </div>
                                    <div className="adminAfterMargin pLeft">
                                        <CheckBox value={this.state.IsAccountDisabled} text={'If checked, user cannot sign-in to the site'} onValueChanged={this.cbAccountDisabledValueChanged} />
                                    </div>
                                    <div className="adminAfterMargin pLeft">
                                        <CheckBox value={this.state.IsRightAdmin} text={'User can administrate the system and other users rights'} onValueChanged={this.cbRightAdminValueChanged} />
                                    </div>
                                    <div className="adminAfterMargin pLeft">
                                        <CheckBox value={this.state.IsRightAdminArticle} text={'User can add/modify/delete articles'} onValueChanged={this.cbRightAdminArticleValueChanged} />
                                    </div>
                                    <div className="adminAfterMargin pLeft">
                                        <CheckBox value={this.state.IsRightKnowledgeProfessional} text={'This user can support others in the forums'} onValueChanged={this.cbRightKnowledgeProfessionalValueChanged} />
                                    </div>
                                    <div className="adminAfterMargin">
                                        <Button
                                            width="15rem"
                                            text="Save"
                                            hint="Save changes"
                                            type="default"
                                            stylingMode="contained"
                                            disabled={this.state.IsButtonsDisabled}
                                            onClick={this.btnUpdateClickHandler}
                                        />
                                    </div>
                                    {this.state.selectedUser !== null && !this.state.selectedUser.IsEmailConfirmed ?
                                        <div className="adminAfterMargin">
                                            <hr />
                                            <Button
                                                width="15rem"
                                                text="Re-Send Confirmation Email"
                                                hint="Re-Send the registration confirmation email"
                                                type="default"
                                                stylingMode="contained"
                                                disabled={this.state.IsButtonsDisabled}
                                                onClick={this.btnReSendClickHandler}
                                            />
                                        </div> : null}
                                </div>
                            </div> : null}
                    </div>
                </div>
                <LoadingPopup isVisible={this.state.showLoading} />
            </Fragment>
        );
    }

    render() {
        return (
            <AuthContext.Consumer>
                {
                    authContext =>
                        <Fragment>
                            {authContext.isAuthenticated ?
                                this.renderAdminSection() : <Redirect push to={Urls.LANDING} />}
                        </Fragment>
                }
            </AuthContext.Consumer>
        );
    }

}
