import React from "react";
import AuthContext from "../Context/Auth/AuthContext";
import UserProfile from "./UserProfile";

const UserProfileHOC = props => {
    return (
        <AuthContext.Consumer>
            {context =>
                <UserProfile authContext={context} {...props} />
            }
        </AuthContext.Consumer>
    );
}

export default UserProfileHOC;
