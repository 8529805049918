import React, { Component, Fragment } from "react";

import { TextArea, Button, ValidationGroup } from 'devextreme-react';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import { Popup } from 'devextreme-react/popup';
import notify from 'devextreme/ui/notify';

import ArticleComment from "./ArticleComment";
import AuthContext from "../Context/Auth/AuthContext";
import ArticleContext from "../Context/Article/ArticleContext";
import ArticleDataSource from "./ArticleDataSource";
import LoadingPopup from "../Popups/LoadingPopup";

import PageTitle from "../Auxilary/PageTitle";

import "./ArticleViewer.css";


class ArticleViewer extends Component {

    state = {
        commentValue: "",
        isPopupVisible: false,
        refreshDummy: false,
        showLoading: false
    }

    componentDidMount() {
        PageTitle.setTitle(this.props.articleContext.header + " | " + PageTitle.defaultText);
    }

    commentChangeHandler = (e) => {
        this.setState({ commentValue: e.value });
    }

    btnLeaveComment = (e, authContext, articleContext) => {
        if (!e.validationGroup.validate().isValid) {
            return;
        }

        this.setState({ showLoading: true });
        ArticleDataSource.createArticleComment(articleContext.articleData.Id,
            authContext.userData.firstName + " " + authContext.userData.lastName,
            this.state.commentValue)
            .then((r) => {
                this.setState({ showLoading: false });
                e.validationGroup.reset();
                notify("Comment successfully sent.", "success", 1000);
            })
            .catch((error) => {
                this.setState({
                    isPopupVisible: true,
                    showLoading: false
                });
            });
    }

    hideFailedPopup = () => {
        this.setState({
            isPopupVisible: false
        });
    }

    renderNewComment = (authContext, articleContext) => {
        return (
            <section className="mt-3">
                <hr />
                <ValidationGroup>
                    <div className="articleViewerPD articleViewerBorder articleViewerBorderRad bottomShadow">
                        <div><p className="articleViewerLCH_p">Leave a comment...</p></div>
                        <div className="articleViewerPD">
                            <TextArea
                                className=""
                                width="100%"
                                height={90}
                                hint="Type some comment here..."
                                showClearButton={true}
                                onValueChanged={this.commentChangeHandler}
                                value={this.state.commentValue}>
                                <Validator>
                                    <RequiredRule message={'Comment is required'} />
                                </Validator>
                            </TextArea>
                        </div>
                        <div className="articleViewerRight mt-1">
                            <Button
                                className="mr-2"
                                width="10rem"
                                text="Send"
                                hint="Send my comment into the forum"
                                type="default"
                                stylingMode="contained"
                                onClick={(e) => this.btnLeaveComment(e, authContext, articleContext)}
                            />
                        </div>
                    </div>
                </ValidationGroup>
                <hr />
            </section>
        );
    }

    renderComments = (authContext, articleContext) => {
        return (
            <Fragment>
                {articleContext.comments.map(c => <ArticleComment key={c.Id} Id={c.Id} ArticleId={articleContext.articleData.Id} Name={c.Name} CreatedTime={c.CreatedTime} Message={c.Message} OwnerUserId={c.OwnerUserId} AuthContext={authContext} IsEdited={c.IsEdited} />)}
                <div className="articleViewerDivAfterComments"></div>
            </Fragment>
        );
    }

    render() {
        return (
            <AuthContext.Consumer>
                {
                    authContext =>
                        <ArticleContext.Consumer>
                            {
                                articleContext =>
                                    <Fragment>
                                        <div className="bottomShadow articleViewerContentPB articleViewerBorderRad mb-5">
                                            <section>
                                                <header>
                                                    <h1>{articleContext.header}</h1>
                                                </header>
                                            </section>
                                            <section className="pb-2">
                                                <main className="dx-htmleditor-content articleViewerHtmlContent" dangerouslySetInnerHTML={{ __html: articleContext.content }}></main>
                                            </section>
                                        </div>
                                        {articleContext.isCommentsAllowed ?
                                            <section>
                                                <footer>
                                                    {authContext.userData.userType >= 1 && articleContext.comments.length > 0 ? <p className="pHeader">Comments</p> : null}
                                                    {authContext.userData.userType >= 1 ? this.renderNewComment(authContext, articleContext) : null}
                                                    {this.renderComments(authContext, articleContext)}
                                                </footer>
                                            </section> : null}
                                        <Popup
                                            visible={this.state.isPopupVisible}
                                            onHiding={this.hideFailedPopup}
                                            dragEnabled={true}
                                            closeOnOutsideClick={true}
                                            showTitle={true}
                                            title="Comment"
                                            width={300}
                                            height={200}>
                                            <div className="articleViewerPopupCenter">
                                                <div className="centeredContent">
                                                    <p className="pCenter">
                                                        Failed to send comment.<br />Please try again a bit later.
                                                    </p>
                                                    <Button
                                                        width="14.5rem"
                                                        text="OK"
                                                        type="default"
                                                        stylingMode="contained"
                                                        onClick={this.hideFailedPopup}
                                                    />
                                                </div>
                                            </div>
                                        </Popup>
                                        <LoadingPopup isVisible={this.state.showLoading} />
                                    </Fragment>
                            }
                        </ArticleContext.Consumer>
                }
            </AuthContext.Consumer>
        );
    }

}

export default ArticleViewer;
