import React, { Component } from "react";

import PageTitle from "../Auxilary/PageTitle";

class ConfirmEmail extends Component {

    componentDidMount() {
        PageTitle.setTitle("Email confirmation | " + PageTitle.defaultText);
    }

    render() {
        return (
            <div className="maxWidthContainer"><p className="pCenter">Email confirmed, thank you.<br/>Now you can login to the site.</p></div>
        );
    }

}

export default ConfirmEmail;
